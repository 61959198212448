<template>
  <div id="ui">
    <div id="ui_centered">
      <div id="ui_content">
        <!-- BUTTONS -->
        <div id="ui_buttons">
          <Button title="Neue Idee" @click="newIdea" />
          <Button
            title="Neue Variation"
            @click="newVariation"
            style="float: right"
          />
          <p />
          <div
            style="display: flex; width: 100%; justify-content: space-between"
          >
            <Button
              title="Herunterladen"
              @click="downloadPoster"
              style="padding: 6px 20px; font-size: 12px"
            />
            <Button
              title="Speichern"
              @click="saveToFile"
              style="font-size: 12px"
            />
            <Button
              title="Laden"
              @click="loadFile"
              style="font-size: 12px; float: right"
            />
          </div>
        </div>

        <!-- COLOR PROPERTIES -->
        <div class="ui_componentRow">
          <Accordion title="Farben" open="false">
            <div class="ui_componentCol" style="padding: 20px 20px 20px 20px">
              <ColorDropdown
                :colors="{ parent: 'colors', key: 'grundfarben' }"
                :bindTo="{ parent: 'design', key: 'backgroundColor' }"
                style="margin-right: 20px"
              />
              <ColorDropdown
                :colors="{ parent: 'colors', key: 'akzenteKomplett' }"
                :bindTo="{ parent: 'design', key: 'accentColor' }"
              />
            </div>
          </Accordion>
        </div>

        <!-- GRID PROPERTIES -->
        <div class="ui_componentRow">
          <Accordion title="Raster" open="false">
            <!-- BORDER SIZE -->
            <div class="ui_componentCol" style="padding: 20px 20px 30px 20px">
              <Slider
                :onComplete="updateGrid"
                :label="'Rahmengr&ouml;&szlig;e'"
                :min="0"
                :max="20"
                :bindTo="{ parent: 'grid', key: 'border' }"
              />
            </div>
            <!-- BASE GRID -->
            <Accordion title="Basis Raster" open="true">
              <div class="ui_componentCol" style="padding: 20px 20px 30px 20px">
                <Slider
                  :onComplete="updateGrid"
                  :label="'Basis Spalten'"
                  :min="1"
                  :max="20"
                  :bindTo="{ parent: 'grid', key: 'base_rows' }"
                  style="margin-right: 20px"
                />
                <Slider
                  :onComplete="updateGrid"
                  :label="'Basis Zeilen'"
                  :min="1"
                  :max="20"
                  :bindTo="{ parent: 'grid', key: 'base_cols' }"
                />
              </div>
              <div class="ui_componentCol" style="padding: 0px 20px 30px 20px">
                <Slider
                  :onComplete="updateDistribution"
                  :label="'Sichtbarkeit'"
                  :min="0"
                  :max="100"
                  :bindTo="{ parent: 'grid', key: 'base_frequency' }"
                  style="margin-right: 20px"
                />
                <Slider
                  :onComplete="updateGrid"
                  :label="'Rotation'"
                  :min="0"
                  :max="360"
                  :bindTo="{ parent: 'grid', key: 'base_rotation' }"
                />
              </div>
              <div
                class="ui_componentCol"
                style="padding: 20px 20px 30px 0px; margin-left: -15px"
              >
                <CheckBox
                  :onComplete="updateShapeTypeDistributionBase"
                  :bindTo="{ parent: 'grid', key: 'base_rectangle' }"
                  ><svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 300 300"
                    style="enable-background: new 0 0 300 300"
                    xml:space="preserve"
                  >
                    <rect width="300" height="300" /></svg
                ></CheckBox>
                <CheckBox
                  :onComplete="updateShapeTypeDistributionBase"
                  :bindTo="{ parent: 'grid', key: 'base_circle' }"
                  ><svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 300 300"
                    style="enable-background: new 0 0 300 300"
                    xml:space="preserve"
                  >
                    <circle cx="150" cy="150" r="150" /></svg
                ></CheckBox>
                <CheckBox
                  :onComplete="updateShapeTypeDistributionBase"
                  :bindTo="{ parent: 'grid', key: 'base_triangle' }"
                  ><svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 300 300"
                    style="enable-background: new 0 0 300 300"
                    xml:space="preserve"
                  >
                    <polygon points="150,0 0,300 300,300 " /></svg
                ></CheckBox>
              </div>
            </Accordion>

            <!-- ACCENT GRID -->
            <Accordion title="Akzent Raster" open="true">
              <div class="ui_componentCol" style="padding: 20px 20px 30px 20px">
                <Slider
                  :onComplete="updateGrid"
                  :label="'Akzent Spalten'"
                  :min="1"
                  :max="20"
                  :bindTo="{ parent: 'grid', key: 'accent_rows' }"
                  style="margin-right: 20px"
                />
                <Slider
                  :onComplete="updateGrid"
                  :label="'Akzent Zeilen'"
                  :min="1"
                  :max="20"
                  :bindTo="{ parent: 'grid', key: 'accent_cols' }"
                />
              </div>
              <div class="ui_componentCol" style="padding: 0px 20px 30px 20px">
                <Slider
                  :onComplete="updateDistribution"
                  :label="'Sichtbarkeit'"
                  :min="0"
                  :max="100"
                  :bindTo="{ parent: 'grid', key: 'accent_frequency' }"
                  style="margin-right: 20px"
                />
                <Slider
                  :onComplete="updateGrid"
                  :label="'Rotation'"
                  :min="0"
                  :max="360"
                  :bindTo="{ parent: 'grid', key: 'accent_rotation' }"
                />
              </div>
              <div
                class="ui_componentCol"
                style="padding: 20px 20px 30px 0px; margin-left: -15px"
              >
                <CheckBox
                  :onComplete="updateShapeTypeDistributionAccent"
                  :bindTo="{ parent: 'grid', key: 'accent_rectangle' }"
                  ><svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 300 300"
                    style="enable-background: new 0 0 300 300"
                    xml:space="preserve"
                  >
                    <rect width="300" height="300" /></svg
                ></CheckBox>
                <CheckBox
                  :onComplete="updateShapeTypeDistributionAccent"
                  :bindTo="{ parent: 'grid', key: 'accent_circle' }"
                  ><svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 300 300"
                    style="enable-background: new 0 0 300 300"
                    xml:space="preserve"
                  >
                    <circle cx="150" cy="150" r="150" /></svg
                ></CheckBox>
                <CheckBox
                  :onComplete="updateShapeTypeDistributionAccent"
                  :bindTo="{ parent: 'grid', key: 'accent_triangle' }"
                  ><svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 300 300"
                    style="enable-background: new 0 0 300 300"
                    xml:space="preserve"
                  >
                    <polygon points="150,0 0,300 300,300 " /></svg
                ></CheckBox>
              </div>
            </Accordion>
          </Accordion>
        </div>

        <!-- DECORATION PROPERTIES -->
        <div class="ui_componentRow">
          <Accordion title="Dekoration" open="false">
            <!-- GRAPHICAL ELEMENTS -->
            <Accordion title="Grafische Elemente" open="true">
              <div style="padding: 20px 20px 30px 20px">
                <Toggle
                  id="decoration_active"
                  label="Grafische Elemente anzeigen"
                  labelOn="An"
                  labelOff="Aus"
                  :bindTo="{ parent: 'design', key: 'decoration' }"
                />
                <p style="margin-bottom: 25px" />
                <RangeSlider
                  :onComplete="updateDecorationDistribution"
                  :min="1"
                  :max="60"
                  :min_bindTo="{ parent: 'design', key: 'decoration_min' }"
                  :max_bindTo="{ parent: 'design', key: 'decoration_max' }"
                  :label="'Anzahl der Elemente'"
                />
                <p style="margin-bottom: 30px" />
                <RangeSlider
                  :min="1"
                  :max="200"
                  :min_bindTo="{ parent: 'design', key: 'decoration_size_min' }"
                  :max_bindTo="{ parent: 'design', key: 'decoration_size_max' }"
                  :label="'Gr&ouml;&szlig;en - Verteilung der Elemente'"
                />
              </div>
            </Accordion>

            <!-- IMAGE ELEMENTS -->
            <Accordion title="Bild-Elemente" open="true">
              <div style="padding: 20px 20px 30px 20px">
                <Toggle
                  id="decorationImages_active"
                  label="Bild-Elemente anzeigen"
                  labelOn="An"
                  labelOff="Aus"
                  :bindTo="{ parent: 'design', key: 'decorationImages' }"
                />
                <p style="margin-bottom: 25px" />
                <RangeSlider
                  :onComplete="updateDecorationImageDistribution"
                  :min="0"
                  :max="maxDecorativeImagesCount"
                  :min_bindTo="{
                    parent: 'design',
                    key: 'decorationImages_min',
                  }"
                  :max_bindTo="{
                    parent: 'design',
                    key: 'decorationImages_max',
                  }"
                  :label="'Anzahl der Elemente'"
                />
                <p style="margin-bottom: 30px" />
                <RangeSlider
                  :min="1"
                  :max="50"
                  :min_bindTo="{
                    parent: 'design',
                    key: 'decorationImages_size_min',
                  }"
                  :max_bindTo="{
                    parent: 'design',
                    key: 'decorationImages_size_min',
                  }"
                  :label="'Gr&ouml;&szlig;en - Verteilung der Elemente'"
                />
              </div>
            </Accordion>
          </Accordion>
        </div>

        <!-- IMAGE PROPERTIES -->
        <div class="ui_componentRow">
          <Accordion title="Bilddaten" open="false">
            <Accordion title="Basis-Bilder" open="true">
              <div class="ui_componentCol" style="padding: 20px 20px 30px 20px">
                <!-- BASE IMAGES -->
                <ImageSelector
                  :bindTo="{ parent: 'images', key: 'normal' }"
                  :updateKey="$store.state.loadOperations.forceUpdate"
                />
              </div>
              <div class="ui_componentCol" style="padding: 0px 20px 20px 20px">
                <Button
                  style="
                    font-size: 12px;
                    padding: 3px 15px 3px 15px;
                    margin-right: 20px;
                  "
                  title="Weitere Bilder laden"
                  @click="loadNormalImages"
                />
                <Button
                  style="font-size: 12px; padding: 3px 15px 3px 15px"
                  title="Alle Bilder entfernen"
                  @click="removeNormalImages"
                />
              </div>
            </Accordion>
            <Accordion title="Transparente Bilder" open="true">
              <div class="ui_componentCol" style="padding: 20px 20px 30px 20px">
                <RangeSlider
                  :min="1"
                  :max="50"
                  :min_bindTo="{ parent: 'design', key: 'alpha_imageSize_min' }"
                  :max_bindTo="{ parent: 'design', key: 'alpha_imageSize_max' }"
                  :label="'Bildgr&ouml;&szlig;en - Verteilung'"
                />
              </div>
              <div class="ui_componentCol" style="padding: 20px 20px 30px 20px">
                <!-- ALPHA IMAGES -->
                <ImageSelector
                  :bindTo="{ parent: 'images', key: 'alpha' }"
                  :updateKey="$store.state.loadOperations.forceUpdate"
                />
              </div>
              <div class="ui_componentCol" style="padding: 0px 20px 20px 20px">
                <Button
                  style="
                    font-size: 12px;
                    padding: 3px 15px 3px 15px;
                    margin-right: 20px;
                  "
                  title="Weitere Bilder laden"
                  @click="loadAlphaImages"
                />
                <Button
                  style="font-size: 12px; padding: 3px 15px 3px 15px"
                  title="Alle Bilder entfernen"
                  @click="removeAlphaImages"
                />
              </div>
            </Accordion>
          </Accordion>
        </div>

        <!-- MASS-EXPORT -->
        <div class="ui_componentRow">
          <Accordion title="Massenexport" open="false">
            <div class="ui_componentCol" style="padding: 20px 20px 10px 20px">
              <NumberInput
                :label="'Grafiken generieren'"
                :bindTo="{ parent: 'export', key: 'exportAmount' }"
              />
            </div>
            <div class="ui_componentCol" style="padding: 0px 20px 20px 20px">
              <NumberInput
                :bindTo="{ parent: 'export', key: 'imageAmount_min' }"
              />
              -<span style="width: 10px"></span>
              <NumberInput
                :label="'Bilder pro Grafik'"
                :bindTo="{ parent: 'export', key: 'imageAmount_max' }"
              />
            </div>
            <div class="ui_componentCol" style="padding: 0px 20px 20px 20px">
              <Button
                style="
                  width: 100%;
                  font-size: 12px;
                  padding: 10px 15px 10px 15px;
                  margin-right: 20px;
                "
                title="Ordner mit Bildpool festlegen"
                @click="loadImagePool"
              />
            </div>
            <div class="ui_componentCol" style="padding: 0px 20px 0px 20px">
              <Button
                style="
                  width: 100%;
                  font-size: 12px;
                  padding: 10px 15px 10px 15px;
                  margin-right: 20px;
                  background: #000;
                  color: #fff;
                "
                title="Grafiken generieren"
                @click="generateRandomImages"
              />
            </div>
            <div style="font-size: 12px; padding: 20px">
              Wichtiger Hinweis: Bitte stellen Sie Ihren Browser so ein, dass
              bei Downloads nicht nach dem Speicherort gefragt wird. So kann Ihr
              Browser automatisch die gewünschte Anzahl an Bildern generieren
              und speichern.
              <p />
              Chrome: "Vor dem Download von Dateien noch Speicherort fragen"
              deaktivieren. Firefox: "Jedes Mal nachfragen, wo eine Datei
              gespeichert werden soll" deaktivieren.
            </div>
          </Accordion>
        </div>
      </div>
    </div>

    <div id="version">Version {{ store.getters.appVersion }}</div>
    <input
      type="file"
      multiple="multiple"
      style="display: none"
      id="normalFileLoader"
    />
    <input
      type="file"
      multiple="multiple"
      style="display: none"
      id="alphaFileLoader"
    />
    <input
      type="file"
      webkitdirectory
      mozdirectory
      accept="image/*"
      style="display: none"
      id="imagePoolLoader"
    />
    <input type="file" style="display: none" id="stateFileLoader" />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { canvasManager } from "../Artboard.vue";
import CanvasRecorder from "../../utils/canvasRecorder.js";
import { randomInt, simulateClick } from "../../utils/utils.js";

import Slider from "./components/component_slider.vue";
import Button from "./components/component_button.vue";
import NumberInput from "./components/component_numberInput.vue";
import Accordion from "./components/component_accordion.vue";
import ColorDropdown from "./components/component_colorDropdown.vue";
import ImageSelector from "./components/component_imageSelector.vue";
import Toggle from "./components/component_toggle.vue";
import RangeSlider from "./components/component_rangeSlider.vue";
import CheckBox from "./components/component_icon_checkBox.vue";
import { ref } from "@vue/reactivity";
import { onMounted, watch } from "@vue/runtime-dom";

export default {
  name: "UI",
  components: {
    Slider,
    Button,
    NumberInput,
    Accordion,
    ColorDropdown,
    ImageSelector,
    RangeSlider,
    CheckBox,
    Toggle,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  setup() {
    // INIT ---------------------------------------------------------------------------------------------
    const store = useStore();
    let recordingActive = false;
    let canvasRecorder = undefined;
    let targetBitrate = 100;
    let recordButtonTitle = ref("Aufnehmen");
    let recordButtonTheme = ref("");
    let maxDecorativeImagesCount = ref(store.state.images.normal.length);

    // Watch changes in the normal image array. If there are changes, update the max available option for the decoration
    watch(
      () => store.getters.normalImagesUpdated,
      function (resultOfGetter) {
        store.dispatch("updateState", {
          parent: "design",
          key: "decorationImages_max",
          value: resultOfGetter,
        });
        maxDecorativeImagesCount.value = resultOfGetter;

        if (
          resultOfGetter > 0 &&
          store.state.design.decorationImages_min == 0
        ) {
          store.dispatch("updateState", {
            parent: "design",
            key: "decorationImages_min",
            value: 1,
          });
        }
      }
    );

    // DOWNLOAD POSTER ---------------------------------------------------------------------------------------------
    function downloadPoster() {
      return new Promise((resolve) => {
        // Trigger the download function at either 5x for retina or 10x for non-retina screens
        canvasManager
          .downloadCanvas(store.state.canvas.retina == true ? 5 : 10)
          .then(() => {
            resolve("OK");
          });
      });
    }

    // RECORD ANIMATION ---------------------------------------------------------------------------------------------
    function recordAnimation() {
      recordingActive = !recordingActive;

      if (recordingActive) {
        // Start recording
        recordButtonTitle.value = "Stop";
        recordButtonTheme.value = "recording";
        canvasRecorder = new CanvasRecorder(
          canvasManager.canvas,
          targetBitrate * 1000000
        );
        canvasRecorder.start();
      } else {
        // Stop recording
        recordButtonTitle.value = "Aufnehmen";
        recordButtonTheme.value = "";
        canvasRecorder.stop();
        canvasRecorder.save(
          "BSG-Recording-" + Math.random().toString(36).substr(2, 9) + ".webm"
        );
      }
    }

    // RESIZE ---------------------------------------------------------------------------------------------
    function triggerResize() {
      canvasManager.resize();
    }

    // SAVE TO FILE ---------------------------------------------------------------------------------------------
    function saveToFile() {
      let currentState = store.getters.getCurrentState;

      var blob = new Blob([currentState], { type: "application/json" });
      var url = URL.createObjectURL(blob);

      var timeStampInMs =
        window.performance &&
        window.performance.now &&
        window.performance.timing &&
        window.performance.timing.navigationStart
          ? window.performance.now() + window.performance.timing.navigationStart
          : Date.now();
      var a = document.createElement("a");
      a.download = "Collage-" + timeStampInMs + ".bsg";
      a.href = url;
      a.click();
    }

    // LOAD FROM FILE ---------------------------------------------------------------------------------------------
    function loadFile() {
      let stateLink = document.getElementById("stateFileLoader");
      simulateClick(stateLink);
    }

    // NEW IDEA ---------------------------------------------------------------------------------------------
    // Create an entirely new idea and select entirely new settings
    function newIdea() {
      canvasManager.newIdea();
    }

    // NEW VARIATION ---------------------------------------------------------------------------------------------
    // Create a new variation based on the select settings
    function newVariation() {
      canvasManager.newVariant();
    }

    // UPDATE GRID ---------------------------------------------------------------------------------------------
    // Update the base grid properties and keep the colors
    function updateGrid() {
      canvasManager.determineColors({ keepAccent: true, keepBackground: true });
    }

    // Update the shape distribution for the base grid (i.e. rectangle, circle, triangle)
    function updateShapeTypeDistributionBase() {
      canvasManager.updateShapeTypes(
        store.state.design.grid_base_color,
        "base"
      );
    }

    // Update the shape distribution for the base grid (i.e. rectangle, circle, triangle)
    function updateShapeTypeDistributionAccent() {
      canvasManager.updateShapeTypes(
        store.state.design.grid_accent_color,
        "accent"
      );
    }

    // Update the amount of decorative elements
    function updateDecorationDistribution() {
      canvasManager.determineDecoration();
    }

    //
    function updateDecorationImageDistribution() {}

    // Update the base grid and the likeliness of both accent and base elements appearing (but keep colors)
    function updateDistribution() {
      canvasManager.determineColors({
        keepAccent: true,
        keepBackground: true,
        redistribute: true,
      });
    }

    // IMAGE HANDLING ---------------------------------------------------------------------------------------------
    // Mount file input event handlers
    onMounted(() => {
      // Normal images
      const normalLoader = document.getElementById("normalFileLoader");
      normalLoader.addEventListener("change", (event) => {
        parseLoadedFiles(event, "images", "normal");
        normalLoader.value = null;
      });

      // Alpha images
      const alphaLoader = document.getElementById("alphaFileLoader");
      alphaLoader.addEventListener("change", (event) => {
        parseLoadedFiles(event, "images", "alpha");
        alphaLoader.value = null;
      });

      // Image pool
      const imagePoolLoader = document.getElementById("imagePoolLoader");
      imagePoolLoader.addEventListener("change", (event) => {
        if (event.target.files.length > 0) {
          let availableFiles = [];

          for (let index in event.target.files) {
            let currentFile = event.target.files[index];
            // If file is of type JPG or PNG
            if (
              typeof currentFile.size != "undefined" &&
              (currentFile.type == "image/png" ||
                currentFile.type == "image/jpeg")
            ) {
              // Push to array
              availableFiles.push(currentFile);
            }
          }

          // Push image-references to state
          store.dispatch("updateState", {
            parent: "export",
            key: "files",
            value: availableFiles,
          });

          // // Clear previous images
          // removeNormalImages();
          // removeAlphaImages();
        }

        imagePoolLoader.value = null;
      });

      // VueX State File Loader
      const vueStateFileLoader = document.getElementById("stateFileLoader");
      vueStateFileLoader.addEventListener("change", (event) => {
        let currentFile = event.target.files[0];
        let reader = new FileReader();
        reader.readAsText(currentFile);

        reader.onload = function (event) {
          store.dispatch("setStateFromFile", {
            loadedData: JSON.parse(event.target.result),
          });
        };

        vueStateFileLoader.value = null;
      });
    });

    function parseLoadedFiles(e, parent, key) {
      // Loop over all files
      for (let file in e.target.files) {
        // Grab the current file
        let currentFile = e.target.files[file];
        // Make sure it is either of type PNG or JPG to continue (otherwise ignore)
        if (
          typeof currentFile.size != "undefined" &&
          (currentFile.type == "image/png" || currentFile.type == "image/jpeg")
        ) {
          // Load the file
          let reader = new FileReader();
          reader.readAsDataURL(currentFile);

          // Once the file has been read...
          reader.onload = function (event) {
            // Create new image and set src
            let image = new Image();
            image.src = event.target.result;
            // Once the file has loaded... (asynchronously)
            image.onload = () => {
              // Convert image to BLOB
              let blobCanvas = document.createElement("CANVAS");
              let blobCtx = blobCanvas.getContext("2d");
              let dataURL;
              blobCanvas.height = image.height;
              blobCanvas.width = image.width;
              blobCtx.drawImage(image, 0, 0);
              dataURL = blobCanvas.toDataURL(currentFile.type);
              blobCanvas = null;

              // Create image object
              let thisImage = {
                blob: dataURL,
                name: currentFile.name,
                image: image,
                aspect: image.height / image.width,
                grayScale: null,
              };

              // Push image to array
              store.dispatch("pushToArray", {
                parent: parent,
                key: key,
                value: thisImage,
              });

              // Push to existing design
              canvasManager.addImageToPlacement(thisImage, key);
            };
          };
        }
      }

      return false;
    }

    function loadRandomImagesFromPool() {
      return new Promise((resolve) => {
        // Determine how many images to use
        let imagesToUse = randomInt(
          store.state.export.imageAmount_min,
          store.state.export.imageAmount_max
        );

        // Grab random elements from image pool
        let shuffled = store.state.export.files.sort(function () {
          return 0.5 - Math.random();
        });
        let selected = shuffled.slice(0, imagesToUse);
        let loadedFiles = 0;

        for (let i = 0; i < selected.length; i++) {
          let currentFile = selected[i];
          // Load the file
          let reader = new FileReader();
          reader.readAsDataURL(currentFile);

          // Once the file has been read...
          reader.onload = function (event) {
            // Create new image and set src
            let image = new Image();
            image.src = event.target.result;
            // Once the file has loaded... (asynchronously)
            image.onload = () => {
              // Create image object
              let thisImage = {
                name: currentFile.name,
                image: image,
                aspect: image.height / image.width,
                grayScale: null,
              };

              // Push image to array
              store.dispatch("pushToArray", {
                parent: "images",
                key: "alpha",
                value: thisImage,
              });

              loadedFiles++;
              if (loadedFiles == imagesToUse) {
                resolve("All files loaded");
              }

              // // Push to existing design
              // canvasManager.addImageToPlacement(thisImage, "alpha");
            };
          };
        }
      });
    }

    // Remove all base images
    function removeNormalImages() {
      // Check if there is a background image present in this form
      if (store.state.design.backgroundImage != null) {
        if (store.state.design.backgroundImage.store == "normal") {
          store.dispatch("updateState", {
            parent: "design",
            key: "backgroundImage",
            value: null,
          });
        }
      }

      // Remove images from image_grid array
      store.state.design.image_grid = store.state.design.image_grid.filter(
        (a) => a.type != "normal"
      );

      // Clear the array
      store.dispatch("clearArray", {
        parent: "images",
        key: "normal",
      });
    }

    function loadNormalImages() {
      let normalLink = document.getElementById("normalFileLoader");
      simulateClick(normalLink);
    }

    // Remove all alpha images
    function removeAlphaImages() {
      // Check if there is a background image present in this form
      if (store.state.design.backgroundImage != null) {
        if (store.state.design.backgroundImage.store == "alpha") {
          store.dispatch("updateState", {
            parent: "design",
            key: "backgroundImage",
            value: null,
          });
        }
      }

      // Remove images from image_grid array
      store.state.design.image_grid = store.state.design.image_grid.filter(
        (a) => a.type != "alpha"
      );

      // Clear the array
      store.dispatch("clearArray", {
        parent: "images",
        key: "alpha",
      });
    }

    function loadAlphaImages() {
      let alphaLink = document.getElementById("alphaFileLoader");
      simulateClick(alphaLink);
    }

    // Mass export
    function loadImagePool() {
      let imagePoolLink = document.getElementById("imagePoolLoader");
      simulateClick(imagePoolLink);
    }

    function generateRandomImages() {
      let exportedImages = 0;
      generateAndSaveNewImage();

      function generateAndSaveNewImage() {
        // Remove previous images
        removeNormalImages();
        removeAlphaImages();

        // Load random images from image pool
        loadRandomImagesFromPool().then(() => {
          // Once all images have been loaded, generate new graphic
          canvasManager.newIdea();

          // And save graphic
          downloadPoster().then(() => {
            // Repeat the process if we have not created enought images
            exportedImages++;
            if (exportedImages < store.state.export.exportAmount) {
              generateAndSaveNewImage();
            }
          });
        });
      }
    }

    // RETURN ---------------------------------------------------------------------------------------------
    return {
      store,
      recordAnimation,
      downloadPoster,
      triggerResize,
      newVariation,
      newIdea,
      updateGrid,
      updateDistribution,
      removeNormalImages,
      removeAlphaImages,
      loadNormalImages,
      loadAlphaImages,
      updateShapeTypeDistributionBase,
      updateShapeTypeDistributionAccent,
      recordButtonTitle,
      recordButtonTheme,
      updateDecorationDistribution,
      updateDecorationImageDistribution,
      maxDecorativeImagesCount,
      saveToFile,
      loadFile,
      loadImagePool,
      generateRandomImages,
    };
  },
};
</script>

<style>
.ui_componentRow > .accordion:nth-of-type(1) > .accordion_header {
  background-color: #000;
  border-bottom: 1px solid #000;
  color: #fff;
}
#ui {
  background: rgb(247, 247, 247);
  width: 400px;
  display: block;
  height: 96vh;
  overflow-y: scroll;
  margin: 30px;
  border-radius: 10px;
}
#ui_centered {
  height: 85%;
  margin-top: 80px;
  display: flex;
  justify-content: center;
}
#ui_content {
  padding: 20px;
  overflow-y: scroll;
}
.ui_componentRow {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  /* margin-top: 50px; */
}
.ui_componentCol {
  display: flex;
  align-items: stretch;
}
#ui_buttons {
  margin-bottom: 50px;
}
#version {
  padding-top: 20px;
  text-transform: uppercase;
  font-size: 10px;
  margin-left: 30px;
}
</style>