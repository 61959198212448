import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

window.stats = null;

require('@/assets/styles/fonts.css')
require('@/assets/styles/main.css')
require('@/assets/styles/responsive.css')

createApp(App).use(store).mount('#app')
