import { useStore } from "vuex";
import { createPoster } from './Poster.js';
import { map, random, hexToRgbA } from '../utils/utils.js';
import AnimationFrame from "../utils/AnimationFrame.js";

export default class CanvasManager {
    constructor(args) {
        // VueX
        this.store = useStore();

        // Parameters
        this.parentContainer = args.parentContainer;
        this.parentContainer.childNodes.forEach((item, index) => { if (item.nodeName === 'CANVAS') { this.canvas = this.parentContainer.childNodes[index]; this.ctx = this.parentContainer.childNodes[index].getContext("2d"); } }) // get the context of the parent container
        this.aspectRatio = 2480 / 3188;
        this.animationHandler = null;
        this.animationLoop = null; // reference to requestAnimationFrame function
        this.resizing = false;
        this.generatingNewColors = false;
        this.imageObjects = [];

        // Reference to all internal canvases
        this.internalCanvases = [];

        // If there is a font argument, preload that font - otherwise, just init the manager
        if (args.font) { this.loadFont(args.font).then((loadedFont) => { this.init(); }); } else { this.init(); }
    }




    // INIT ---------------------------------------------------------------------------------------------
    init() {
        this.setup();
        this.preloadImages();
    }





    // SETUP ---------------------------------------------------------------------------------------------
    setup() {
        // Send data to VueX
        this.store.dispatch("updateState", { parent: "canvas", key: "ctx", value: this.ctx, });
        this.store.dispatch("updateState", { parent: "canvas", key: "id", value: "vueCanvas" });

        // Resize to determine correct dimensions
        this.resize();

        // Determine colors (only colors as we are waiting for images to be preloaded)
        this.determineColors();

        // Create decoration elements
        this.determineDecoration();

        // Start the animation loop
        this.startAnimationLoop();

        // Attach mouse handler events
        this.attachMouseHandler();
    }



    // MOUSE INTERACTIVITY TO MOVE IMAGES AROUND ---------------------------------------------------------------------------------------------
    attachMouseHandler() {
        let pointerStyle = 'default';
        let hoveredElement = null;
        let clicked = false;
        let clickType = null;
        let mouseOrigin = {
            x: null,
            y: null
        }

        document.getElementById('vueCanvas').addEventListener('mousedown', e => {
            clicked = true;
            switch (e.button) {
                case 0:
                    clickType = 'left';
                    break;
                case 2:
                    clickType = 'right';
                    break;
            }
            mouseOrigin.x = e.clientX;
            mouseOrigin.y = e.clientY;
        });
        document.body.addEventListener('mouseup', e => {
            clicked = false;
            pointerStyle = 'default';
            document.body.style.cursor = pointerStyle;
            hoveredElement = null;
            clickType = null;
            mouseOrigin = {
                x: null,
                y: null
            }
        });

        document.getElementById('vueCanvas').addEventListener('mousemove', e => {
            let dpr = this.store.state.canvas.retina ? 2 : 1;
            let mouse = { x: e.clientX, y: e.clientY, offsetX: e.offsetX * dpr, offsetY: e.offsetY * dpr }
            let elementFound = false;

            // Check if the user has clicked
            if (clicked && hoveredElement != null) {
                let vueCanvas = document.getElementById('vueCanvas');
                let offsets = vueCanvas.getBoundingClientRect();
                let border = this.store.state.grid.border * this.width * 0.01;

                // On left click: Move the element around
                if (clickType == 'left') {
                    // Make sure we only accept mouse-movements withing the border of our design
                    if (mouse.offsetX > border && mouse.offsetX < offsets.width * dpr - border) {
                        hoveredElement.reference.position.x = map(mouse.offsetX, border, offsets.width * dpr - border, 0, 1);
                    }
                    if (mouse.offsetY > border && mouse.offsetY < offsets.height * dpr - border) {
                        hoveredElement.reference.position.y = map(mouse.offsetY, border, offsets.height * dpr - border, 0, 1);
                    }
                }
                if (clickType == 'right') {
                    if (mouseOrigin.y < mouse.y) {
                        if (hoveredElement.reference.dimensions.width < 15 && hoveredElement.reference.dimensions.height < 15) {
                            hoveredElement.reference.dimensions.width *= 1.02;
                            hoveredElement.reference.dimensions.height *= 1.02;
                        }
                    }
                    if (mouseOrigin.y > mouse.y) {
                        if (hoveredElement.reference.dimensions.width > 0.3 && hoveredElement.reference.dimensions.height > 0.3) {
                            hoveredElement.reference.dimensions.width *= .98;
                            hoveredElement.reference.dimensions.height *= .98;
                        }
                    }
                    mouseOrigin.x = e.clientX;
                    mouseOrigin.y = e.clientY;
                }
            } else {
                // If there is no click: Scan for new hit-targets on hover ->
                // Parse all images on the canvas and check if the mouse hovers over one
                for (let index in this.imageObjects) {
                    let currentImage = this.imageObjects[index];
                    if (
                        mouse.offsetX >= currentImage.position.x &&
                        mouse.offsetX <= (currentImage.position.x + currentImage.dimensions.width) &&
                        mouse.offsetY >= currentImage.position.y &&
                        mouse.offsetY <= (currentImage.position.y + currentImage.dimensions.height)
                    ) {
                        // Change mouse cursor
                        pointerStyle = 'pointer';
                        document.body.style.cursor = pointerStyle;

                        // Mark as found and store reference
                        elementFound = true;
                        hoveredElement = currentImage;
                    }
                }

                // Return the mouse cursor to normal if no objects are hovered
                if (!elementFound && pointerStyle != 'default') {
                    pointerStyle = 'default';
                    document.body.style.cursor = pointerStyle;
                    hoveredElement = null;
                }
            }
        });
    }




    // GENERATE NEW DISTRIBUTION OF GRID AND IMAGES ---------------------------------------------------------------------------------------------
    generateNewDesign(keepColors) {
        if (keepColors) {
            this.determineColors();
        } else {
            this.determineColors({ keepAccent: true, keepBackground: true });
        }
        this.determineImagePlacement();
        this.determineDecoration();
        this.determineImageDecoration();
    }




    // DETERMINE DECORATIVE IMAGE ELEMENTS ---------------------------------------------------------------------------------------------
    determineImageDecoration() {
        // Clear array
        this.store.dispatch("clearArray", { parent: "design", key: "decorationImages_grid", });

        if (this.store.state.images.normal.length > 0) {
            // Determine amount of decorative elements
            let decorativeCount = Math.round(random(parseFloat(this.store.state.design.decorationImages_min), parseFloat(this.store.state.design.decorationImages_max)));

            for (let i = 0; i < decorativeCount; i++) {
                // Set decoration object properties
                let decoration = {
                    image: this.store.state.images.normal[i].image,
                    arrayPosition: Math.floor(random(0, 3)), // random number between 0 and 2 to determine placement in array
                    position: { x: random(0, 1), y: random(0, 1) },
                    dimensions: { width: 0, height: 0 },
                    aspect: this.store.state.images.normal[i].aspect,
                    name: this.store.state.images.normal[i].name,
                }
                decoration.dimensions.width = random(0, 10);
                decoration.dimensions.height = decoration.dimensions.width * this.store.state.images.normal[i].aspect;

                // Push to decoration_grid array
                this.store.dispatch("pushToArray", { parent: "design", key: "decorationImages_grid", value: decoration });
            }
        }
    }





    // DETERMINE DECORATIVE ELEMENTS ---------------------------------------------------------------------------------------------
    determineDecoration() {
        // Clear array
        this.store.dispatch("clearArray", { parent: "design", key: "decoration_grid", });

        // Determine amount of decorative elements
        let decorativeCount = Math.floor(random(parseFloat(this.store.state.design.decoration_min), parseFloat(this.store.state.design.decoration_max)));

        for (let i = 0; i < decorativeCount; i++) {
            // Set decoration object properties
            let decoration = {
                position: { x: random(0, 1), y: random(0, 1) },
                dimensions: random(0, 10),
                color: this.store.state.design.accentColor,
                type: Math.floor(random(0, 3)),
                rotation: Math.floor(random(0, 4)),
            }

            // 70% Chance to actually use a color from the base color palette
            if (Math.random() < 0.7) {
                decoration.color = this.store.state.colors.grundfarben[Math.floor(random(0, this.store.state.colors.grundfarben.length))]
            }

            // Push to decoration_grid array
            this.store.dispatch("pushToArray", { parent: "design", key: "decoration_grid", value: decoration });
        }
    }



    // DETERMINE IMAGE PLACEMENT FOR THIS POSTER INSTANCE ---------------------------------------------------------------------------------------------
    determineImagePlacement() {
        // Clear all arrays
        this.store.dispatch("clearArray", { parent: "design", key: "image_grid", });

        // Determine unique configuration for each image
        for (let index in this.store.state.images.alpha) {
            let thisImage = {
                image: this.store.state.images.alpha[index].image,
                arrayPosition: Math.floor(random(0, 3)), // random number between 0 and 2 to determine placement in array
                dimensions: { width: 0, height: 0 },
                position: { x: random(0, 1), y: random(0, 1) },
                name: this.store.state.images.alpha[index].name,
                aspect: this.store.state.images.alpha[index].aspect,
                type: 'alpha',
            }
            thisImage.dimensions.width = random(0, 10);
            thisImage.dimensions.height = thisImage.dimensions.width * this.store.state.images.alpha[index].aspect;

            // Push to image_grid array
            this.store.dispatch("pushToArray", { parent: "design", key: "image_grid", value: thisImage });
        }
    }


    // ADD IMAGE TO EXISTING IMAGE PLACEMENT ---------------------------------------------------------------------------------------------
    addImageToPlacement(imageReference, imageType) {
        // Determine unique configuration for each image
        let thisImage = {
            image: imageReference.image,
            arrayPosition: Math.floor(random(0, 3)), // random number between 0 and 2 to determine placement in array
            dimensions: { width: 0, height: 0 },
            position: { x: random(0, 1), y: random(0, 1) },
            name: imageReference.name,
            aspect: imageReference.aspect,
            type: imageType,
        }
        thisImage.dimensions.width = random(0, 10);
        thisImage.dimensions.height = thisImage.dimensions.width * imageReference.aspect;

        // Push to image_grid array
        this.store.dispatch("pushToArray", { parent: "design", key: "image_grid", value: thisImage });
    }



    // DETERMINE COLOR SET FOR THIS POSTER INSTANCE ---------------------------------------------------------------------------------------------
    determineColors({ keepAccent = false, keepBackground = false, redistribute = false } = {}) {
        this.generatingNewColors = true;

        // ---- BACKGROUND COLOR
        if (!keepBackground) {
            this.store.dispatch("updateState", { parent: "design", key: "base_grid", value: [] });
            this.store.dispatch("updateState", { parent: "design", key: "backgroundColor", value: this.store.state.colors.grundfarben[Math.floor(random(0, this.store.state.colors.grundfarben.length))] });
        }


        // ---- REDISTRIBUTE VISIBILITY OF EXISTING BLOCKS IN THE GRID
        if (redistribute) {
            // Redistribute base grid color
            for (let i = 0; i < this.store.state.design.grid_base_color.length; i++) {
                let gridBlockColorSettings = this.store.state.design.grid_base_color[i];

                if (gridBlockColorSettings.chanceOfVisibility > this.store.state.grid.base_frequency * 0.01) {
                    gridBlockColorSettings.computedColor = hexToRgbA(gridBlockColorSettings.color, 0);
                } else {
                    gridBlockColorSettings.computedColor = hexToRgbA(gridBlockColorSettings.color, 1);
                }
            }

            // Redistribute accent grid color
            for (let i = 0; i < this.store.state.design.grid_accent_color.length; i++) {
                let gridBlockColorSettings = this.store.state.design.grid_accent_color[i];

                if (gridBlockColorSettings.chanceOfVisibility > this.store.state.grid.accent_frequency * 0.01) {
                    gridBlockColorSettings.computedColor = hexToRgbA(gridBlockColorSettings.color, 0);
                } else {
                    gridBlockColorSettings.computedColor = hexToRgbA(gridBlockColorSettings.color, 1);
                }
            }
        }

        // ---- BASE GRID
        // Build array that conatins selectios of color for this grid-configuration
        for (let x = 0; x < this.store.state.grid.base_rows; x++) {
            for (let y = 0; y < this.store.state.grid.base_cols; y++) {
                // Determine a random color from the base color array
                let selectedColor = this.store.state.colors.grundfarben[Math.floor(random(0, this.store.state.colors.grundfarben.length))];
                if (selectedColor == this.store.state.design.backgroundColor) { // Make sure the selected color does not match the background color
                    selectedColor = this.store.state.colors.grundfarben[Math.floor(random(0, this.store.state.colors.grundfarben.length))];
                }
                let gridBlockColorSettings = {
                    color: selectedColor,
                    chanceOfVisibility: Math.random(),
                    computedColor: null,
                    shapeType: null,
                }
                // Compute color and determine if this is initially visible
                gridBlockColorSettings.computedColor = hexToRgbA(gridBlockColorSettings.color, 1);
                if (gridBlockColorSettings.chanceOfVisibility > this.store.state.grid.base_frequency * 0.01) {
                    gridBlockColorSettings.computedColor = hexToRgbA(gridBlockColorSettings.color, 0);
                }
                // Push color to array
                if (this.store.state.design.grid_base_color.length < 400) {
                    this.store.dispatch("pushToArray", { parent: "design", key: "grid_base_color", value: gridBlockColorSettings });
                }
            }
        }

        // Update the just created base array by determining which of those are squares, circles and rectangles
        this.updateShapeTypes(this.store.state.design.grid_base_color, "base");

        // ---- ACCENT GRID
        // Pick an accent style
        if (!keepAccent) {
            this.store.dispatch("updateState", { parent: "design", key: "grid_accent_color", value: [] });
            let accentStyle = Math.floor(random(0, this.store.state.colors.akzente.length));
            this.store.dispatch("updateState", { parent: "design", key: "accentColor", value: this.store.state.colors.akzente[accentStyle][Math.floor(random(0, this.store.state.colors.akzente[accentStyle].length))] });
        } else {
            // Re-color but keep other values intact
            for (let i = 0; i < this.store.state.design.grid_accent_color.length; i++) {
                let colorData = this.store.state.design.grid_accent_color[i];
                let newComputedColor = hexToRgbA(this.store.state.design.accentColor, 1);
                if (colorData.chanceOfVisibility > this.store.state.grid.accent_frequency * 0.01) {
                    newComputedColor = hexToRgbA(this.store.state.design.accentColor, 0);
                }
                this.store.state.design.grid_accent_color[i].color = this.store.state.design.accentColor;
                this.store.state.design.grid_accent_color[i].computedColor = newComputedColor;
            }
        }

        // Build array that conatins selections of color for this grid-configuration
        for (let x = 0; x < this.store.state.grid.accent_rows; x++) {
            for (let y = 0; y < this.store.state.grid.accent_cols; y++) {
                // Determine a random color from the accent color array
                let gridBlockColorSettings = {
                    color: this.store.state.design.accentColor,
                    chanceOfVisibility: Math.random(),
                    computedColor: null,
                }
                // Compute color and determine if this is initially visible
                gridBlockColorSettings.computedColor = hexToRgbA(gridBlockColorSettings.color, 1);
                if (gridBlockColorSettings.chanceOfVisibility > this.store.state.grid.accent_frequency * 0.01) {
                    gridBlockColorSettings.computedColor = hexToRgbA(gridBlockColorSettings.color, 0);
                }
                // Push color to array
                if (this.store.state.design.grid_accent_color.length < 400) {
                    this.store.dispatch("pushToArray", { parent: "design", key: "grid_accent_color", value: gridBlockColorSettings });
                }
            }
        }
        // Update the just created base array by determining which of those are squares, circles and rectangles
        this.updateShapeTypes(this.store.state.design.grid_accent_color, "accent");


        this.generatingNewColors = false;
    }



    updateShapeTypes(storeReference, gridType) {
        let shapeOptions = 0;

        // Count how many options are selected (i.e. which of rectangle, triangle and circle is selected)
        if (gridType == "base") {
            let countArray = [];
            countArray.push(this.store.state.grid.base_rectangle);
            countArray.push(this.store.state.grid.base_circle);
            countArray.push(this.store.state.grid.base_triangle);
            shapeOptions = countArray.filter(Boolean).length;
        }
        if (gridType == "accent") {
            let countArray = [];
            countArray.push(this.store.state.grid.accent_rectangle);
            countArray.push(this.store.state.grid.accent_circle);
            countArray.push(this.store.state.grid.accent_triangle);
            shapeOptions = countArray.filter(Boolean).length;
        }

        // Update the data accordingly so that we have random distributions of the available options
        for (let index in storeReference) {
            let randomShape = 0;
            // If we only have one shape selected, find out which one it is
            if (shapeOptions == 1) {
                if (gridType == "base") {
                    if (this.store.state.grid.base_rectangle) randomShape = 1;
                    if (this.store.state.grid.base_circle) randomShape = 2;
                    if (this.store.state.grid.base_triangle) randomShape = 3;
                }
                if (gridType == "accent") {
                    if (this.store.state.grid.accent_rectangle) randomShape = 1;
                    if (this.store.state.grid.accent_circle) randomShape = 2;
                    if (this.store.state.grid.accent_triangle) randomShape = 3;
                }
            }
            // For more than one shape option, find a random distribution
            if (shapeOptions > 1) {
                if (gridType == "base") {
                    if (this.store.state.grid.base_rectangle && this.store.state.grid.base_circle && !this.store.state.grid.base_triangle) randomShape = Math.round(random(1, 2));
                    if (!this.store.state.grid.base_rectangle && this.store.state.grid.base_circle && this.store.state.grid.base_triangle) randomShape = Math.round(random(2, 3));
                    if (this.store.state.grid.base_rectangle && this.store.state.grid.base_circle && this.store.state.grid.base_triangle) randomShape = Math.round(random(1, 3));
                    if (this.store.state.grid.base_rectangle && !this.store.state.grid.base_circle && this.store.state.grid.base_triangle) {
                        let rnd = Math.random();
                        if (rnd < 0.5) randomShape = 1; else randomShape = 3;
                    };
                }

                if (gridType == "accent") {
                    if (this.store.state.grid.accent_rectangle && this.store.state.grid.accent_circle && !this.store.state.grid.accent_triangle) randomShape = Math.round(random(1, 2));
                    if (!this.store.state.grid.accent_rectangle && this.store.state.grid.accent_circle && this.store.state.grid.accent_triangle) randomShape = Math.round(random(2, 3));
                    if (this.store.state.grid.accent_rectangle && this.store.state.grid.accent_circle && this.store.state.grid.accent_triangle) randomShape = Math.round(random(1, 3));
                    if (this.store.state.grid.accent_rectangle && !this.store.state.grid.accent_circle && this.store.state.grid.accent_triangle) {
                        let rnd = Math.random();
                        if (rnd < 0.5) randomShape = 1; else randomShape = 3;
                    };
                }

                // randomShape = Math.round(random(1, shapeOptions))
            }

            // Update store
            storeReference[index].shapeType = randomShape;
        }
    }



    // CREATE NEW IDEA ---------------------------------------------------------------------------------------------
    newIdea() {
        // GRID
        // -------------
        this.store.dispatch("updateState", { parent: "grid", key: "border", value: Math.round(random(5, 15)) });
        this.store.dispatch("updateState", { parent: "grid", key: "base_rows", value: Math.round(random(1, 10)) });
        this.store.dispatch("updateState", { parent: "grid", key: "base_cols", value: Math.round(random(1, 10)) });
        this.store.dispatch("updateState", { parent: "grid", key: "base_frequency", value: Math.round(random(10, 80)) });
        this.store.dispatch("updateState", { parent: "grid", key: "accent_rows", value: Math.round(random(1, 10)) });
        this.store.dispatch("updateState", { parent: "grid", key: "accent_cols", value: Math.round(random(1, 10)) });
        this.store.dispatch("updateState", { parent: "grid", key: "accent_frequency", value: Math.round(random(10, 50)) });

        // Randomize rotation
        let changeRotation = Math.random();
        if (changeRotation < 0.3) {
            let newRotation = random(45, 70);
            this.store.dispatch("updateState", { parent: "grid", key: "base_rotation", value: newRotation });
            this.store.dispatch("updateState", { parent: "grid", key: "accent_rotation", value: newRotation });
        } else {
            this.store.dispatch("updateState", { parent: "grid", key: "base_rotation", value: 0 });
            this.store.dispatch("updateState", { parent: "grid", key: "accent_rotation", value: 0 });
        }

        // Select base shapes
        this.store.dispatch("updateState", { parent: "grid", key: "base_rectangle", value: Math.random() < 0.7 ? true : false });
        this.store.dispatch("updateState", { parent: "grid", key: "base_circle", value: Math.random() < 0.5 ? true : false });
        this.store.dispatch("updateState", { parent: "grid", key: "base_triangle", value: Math.random() < 0.5 ? true : false });

        // Select accent shapes
        this.store.dispatch("updateState", { parent: "grid", key: "accent_rectangle", value: Math.random() < 0.7 ? true : false });
        this.store.dispatch("updateState", { parent: "grid", key: "accent_circle", value: Math.random() < 0.5 ? true : false });
        this.store.dispatch("updateState", { parent: "grid", key: "accent_triangle", value: Math.random() < 0.5 ? true : false });



        // DECORATION
        // -------------
        this.store.dispatch("updateState", { parent: "design", key: "decoration", value: Math.random() < 0.5 ? true : false });
        this.store.dispatch("updateState", { parent: "design", key: "decoration_min", value: Math.floor(random(5, 30)) });
        this.store.dispatch("updateState", { parent: "design", key: "decoration_max", value: Math.floor(random(this.store.state.design.decoration_min, this.store.state.design.decoration_min + 30)) });

        this.store.dispatch("updateState", { parent: "design", key: "decoration_size_min", value: Math.floor(random(5, 20)) });
        this.store.dispatch("updateState", { parent: "design", key: "decoration_size_max", value: Math.floor(random(this.store.state.design.decoration_size_min, this.store.state.design.decoration_size_min + 40)) });

        // IMAGE DECORATION
        // -------------
        this.store.dispatch("updateState", { parent: "design", key: "decorationImages", value: Math.random() < 0.3 ? true : false });

        this.store.dispatch("updateState", { parent: "design", key: "decorationImages_size_min", value: Math.floor(random(10, 20)) });
        this.store.dispatch("updateState", { parent: "design", key: "decorationImages_size_max", value: Math.floor(random(this.store.state.design.decoration_size_min, this.store.state.design.decoration_size_min + 40)) });



        // TRANSPARENT IMAGES
        // -------------
        this.store.dispatch("updateState", { parent: "design", key: "alpha_imageSize_min", value: Math.floor(random(10, 15)) });
        this.store.dispatch("updateState", { parent: "design", key: "alpha_imageSize_max", value: Math.floor(random(this.store.state.design.alpha_imageSize_min, this.store.state.design.alpha_imageSize_min + 30)) });



        // RANDOMIZE BACKROUND
        // -------------
        let useBackground = Math.random();
        if (useBackground < 0.35) {
            let selectedImage = this.store.state.images.normal[Math.floor(random(this.store.state.images.normal.length))];
            this.store.dispatch("updateState", { parent: "design", key: "backgroundImage", value: selectedImage });
        } else {
            this.store.dispatch("updateState", { parent: "design", key: "backgroundImage", value: null });
        }

        this.generateNewDesign(true);
    }


    // CREATE NEW, RANDOMIZED VARIANT ---------------------------------------------------------------------------------------------
    newVariant() {
        this.store.dispatch("updateState", { parent: "grid", key: "border", value: Math.round(random(5, 15)) });
        this.store.dispatch("updateState", { parent: "grid", key: "base_rows", value: Math.round(random(1, 10)) });
        this.store.dispatch("updateState", { parent: "grid", key: "base_cols", value: Math.round(random(1, 10)) });
        this.store.dispatch("updateState", { parent: "grid", key: "base_frequency", value: Math.round(random(10, 80)) });
        this.store.dispatch("updateState", { parent: "grid", key: "accent_rows", value: Math.round(random(1, 10)) });
        this.store.dispatch("updateState", { parent: "grid", key: "accent_cols", value: Math.round(random(1, 10)) });
        this.store.dispatch("updateState", { parent: "grid", key: "accent_frequency", value: Math.round(random(10, 50)) });

        this.generateNewDesign(false);
    }






    // SET BACKGROUND IMAGE ---------------------------------------------------------------------------------------------
    setBackgroundImage(imageData) {
        let newBackgroundImage = null;
        let removeBackground = false;

        if (this.store.state.design.backgroundImage != null) {
            if (this.store.state.design.backgroundImage.name == imageData.name) {
                removeBackground = true;
            }
        }

        if (!removeBackground) {
            if (imageData.store == "normal") {
                for (let i = 0; i < this.store.state.images.normal.length; i++) {
                    if (this.store.state.images.normal[i].name == imageData.name) {
                        newBackgroundImage = this.store.state.images.normal[i];
                        newBackgroundImage.store = "normal";
                    }
                }
            }

            if (imageData.store == "alpha") {
                for (let i = 0; i < this.store.state.images.alpha.length; i++) {
                    if (this.store.state.images.alpha[i].name == imageData.name) {
                        newBackgroundImage = this.store.state.images.alpha[i];
                        newBackgroundImage.store = "alpha";
                    }
                }
            }
        }

        this.store.dispatch("updateState", { parent: "design", key: "backgroundImage", value: newBackgroundImage });
    }






    // CREATE INTERNAL CANVAS ---------------------------------------------------------------------------------------------
    createCanvas(canvasWidth, canvasHeight) {
        let internalCanvas = {
            canvas: null,
            t: null
        }

        // Create Canvas
        internalCanvas.canvas = document.createElement('canvas');
        internalCanvas.canvas.width = canvasWidth;
        internalCanvas.canvas.height = canvasHeight;

        // Get context
        internalCanvas.ctx = internalCanvas.canvas.getContext("2d");

        // Push to list of internal canvases
        this.internalCanvases.push(internalCanvas);

        // Return canvas
        return internalCanvas;
    }





    // RESIZE ---------------------------------------------------------------------------------------------
    resize() {
        return new Promise(resolve => {
            this.resizing = true;

            // Get the device pixel ratio, falling back to 1.
            this.dpr = window.devicePixelRatio || 1;
            // Update the store with a boolean referencing the retina state
            this.store.dispatch("updateState", { parent: "canvas", key: "retina", value: this.dpr == 1 ? false : true });

            // Calculate new dimensions based on the parent container width
            let dimensions = {
                width: this.parentContainer.clientWidth,
                height: this.parentContainer.clientWidth / this.aspectRatio
            }

            if (dimensions.height > this.parentContainer.clientHeight) {
                // If the calculated dimensions exceed the height of the window, adjust to that
                dimensions.width = this.parentContainer.clientHeight * this.aspectRatio;
                dimensions.height = this.parentContainer.clientHeight;

                // Retina schaling
                this.canvas.style.width = "auto";
                this.canvas.style.height = "100%";
            } else {
                // Retina schaling
                this.canvas.style.width = "100%";
                this.canvas.style.height = "auto";
            }

            // Dispatch new dimensions to store and updated internal variables to main canvas
            this.store.dispatch("updateState", { parent: "canvas", key: "width", value: dimensions.width * this.dpr * this.store.state.canvas.scale });
            this.store.dispatch("updateState", { parent: "canvas", key: "height", value: dimensions.height * this.dpr * this.store.state.canvas.scale });
            this.width = this.store.state.canvas.width
            this.height = this.store.state.canvas.height;

            // Resize all internal canvases
            for (const internalCanvas of this.internalCanvases) {
                // DO: Make this a variable, based on the canvas dimensions (not hardcoded to the width like now)
                internalCanvas.canvas.width = this.width;
                internalCanvas.canvas.height = this.width;
            }

            this.resizing = false;
            resolve('Resize event complete');
        });
    }




    // FORCE UPDATE OF IMAGE COMPONENT ---------------------------------------------------------------------------------------------
    forceUpdate() {
        setTimeout(() => {
            this.store.dispatch("updateState", {
                parent: "loadOperations",
                key: "forceUpdate",
                value: 1,
            });
        }, 1000);
    }




    // PRELOAD IMAGES ---------------------------------------------------------------------------------------------
    preloadImages() {
        // Clear arrays store (relevant for hot-reloading)
        this.store.state.images.normal = [];
        this.store.state.images.alpha = [];

        // Determine how many images we are going to preload (used to determine when we can set "preloded" to true)
        let imagesToPreload = this.store.state.images.demo_normal.length + this.store.state.images.demo_alpha.length;
        let loadedImages = 0;

        // Load normal images
        loadImages("images/demoImages/normal/", this.store, this.store.state.images.demo_normal, { parent: "images", key: "normal" }, this);

        // Load alpha images
        loadImages("images/demoImages/alpha/", this.store, this.store.state.images.demo_alpha, { parent: "images", key: "alpha" }, this);

        // Function to load the images, parse them once fetched and push into state
        function loadImages(folder, store, loadFrom, bindTo, that) {
            for (let currentImage in loadFrom) {
                let imageToLoad = store.state.folders.base + folder + loadFrom[currentImage];
                let imageName = loadFrom[currentImage];
                let image = new Image();
                image.onload = () => {
                    // Convert image to BLOB
                    let blobCanvas = document.createElement('CANVAS');
                    let blobCtx = blobCanvas.getContext('2d');
                    let dataURL;
                    blobCanvas.height = image.height;
                    blobCanvas.width = image.width;
                    blobCtx.drawImage(image, 0, 0);

                    let mimeType = 'image/png';
                    if (bindTo.key == 'normal') { mimeType = 'image/jpeg' }

                    dataURL = blobCanvas.toDataURL(mimeType);
                    blobCanvas = null;

                    // Create image object
                    let thisImage = {
                        blob: dataURL,
                        name: imageName,
                        image: image,
                        aspect: image.height / image.width,
                        grayScale: null
                    }

                    // Push image to array
                    store.dispatch("pushToArray", {
                        parent: bindTo.parent,
                        key: bindTo.key,
                        value: thisImage,
                    });

                    // Increae counter and when all images are loaded, set state of "preloaded" to true
                    loadedImages++;
                    if (loadedImages == imagesToPreload) {
                        store.dispatch("updateState", { parent: "images", key: "preloaded", value: true });
                    }
                }
                image.src = imageToLoad;
            }
        }
    }




    // LOAD A FONT, PROMISE BASED ---------------------------------------------------------------------------------------------
    loadFont(pathToUrl) {
        return new Promise(resolve => {
            // Define font to load
            let fontToLoad = new FontFace('ActiveFont', 'url(' + pathToUrl + ')');

            // Load that font
            fontToLoad.load().then(function (loaded_fontFace) {
                document.fonts.add(loaded_fontFace);
                resolve(loaded_fontFace);
            }).catch(function (error) {
                console.error('Error loading font: ' + error);
            });
        });
    }





    // START ANIMATION LOOP ---------------------------------------------------------------------------------------------

    startAnimationLoop() {
        let animFrame = new AnimationFrame(30, this.animate, this);
        animFrame.start();

        // this.animationHandler = this.animate.bind(this);
        // this.animate();
    }





    // STOP ANIMATION LOOP ---------------------------------------------------------------------------------------------
    stopAnimationLoop() {
        cancelAnimationFrame(this.animationLoop);
        this.animationHandler = null;
    }





    // ANIMATION HANDLER ---------------------------------------------------------------------------------------------
    animate(delta, reference) {
        reference.render();

        // this.render();
        // this.animationLoop = requestAnimationFrame(this.animationHandler);
    }





    // RENDER LOOP LOGIC ---------------------------------------------------------------------------------------------
    render() {
        this.update();
        this.draw();
    }





    // UPDATE LOGIC ---------------------------------------------------------------------------------------------
    update() {
        // Stats
        if (window.stats != null) window.stats.begin();

        // FrameCount
        this.store.dispatch("animation_increaseFrameCount");
    }





    // DRAW LOGIC ---------------------------------------------------------------------------------------------
    draw() {
        // Only execute this if there is no resize event happening
        if (!this.resizing) {
            // Create design
            createPoster(this, this.ctx, this.store, this.base);
        }

        // Stats
        if (window.stats != null) window.stats.end();
    }





    // DOWNLOAD THE CANVAS AS A PNG ---------------------------------------------------------------------------------------------
    downloadCanvas(scale) {
        return new Promise((resolve) => {
            // Show modal
            this.store.dispatch("updateState", { parent: "images", key: "generatingGraphic", value: true });

            // The timeout is needed for the UI to adapt to the state change and actually show the modal window
            setTimeout(() => {
                // Resize to desired scale
                const originalScale = this.store.state.canvas.scale;
                this.stopAnimationLoop();
                this.store.dispatch("updateState", { parent: "canvas", key: "scale", value: scale });
                this.resize().then(() => {
                    // Draw high res image
                    this.draw();

                    // Create graphic at desired scale
                    let downloadLink = document.createElement('a');
                    downloadLink.download = 'BSG-Poster.jpg';
                    downloadLink.href = this.canvas.toDataURL("image/jpeg");
                    downloadLink.click();

                    // Return to original scale
                    this.store.dispatch("updateState", { parent: "canvas", key: "scale", value: originalScale });
                    this.resize();
                    this.startAnimationLoop();

                    // Hide modal
                    this.store.dispatch("updateState", { parent: "images", key: "generatingGraphic", value: false });

                    // Resolve
                    resolve('OK');
                });
            }, 700);
        });
    }





    // FONT SIZE CALCULATIONS ---------------------------------------------------------------------------------------------
    getFontWidth(ctx, text) {
        let actualWidth = ctx.measureText(text).width;
        return actualWidth;
    }
    getFontHeight(ctx, text) {
        // let actualHeight = ctx.measureText(text).actualBoundingBoxDescent / 2;
        let metrics = ctx.measureText(text);
        let actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
        return actualHeight;
    }

}

