<!-- <div class="accordion" :class="openState == true ? 'open' : ''" :style="{'height': accordion_height}"> -->

<template>
  <div class="accordion">
    <div class="accordion_header" @click="toggleAccordion">
      <div class="accordion_header_icon" :class="openState == true ? 'open' : ''"></div>
      {{ title }}
    </div>
    <div class="accordion_content" :style="{'height': accordion_height}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-dom";

export default {
  props: ["title", "open"],
  setup(props) {
    let openState = ref(null);
    let accordion_height = ref(0);

    onMounted(() => {
      if (props.open == "true") {
        accordion_height.value = "auto";
        openState.value = true;
      } else {
        accordion_height.value = 0;
        openState.value = false;
      }
    });

    function toggleAccordion() {
      openState.value = !openState.value;
      if (accordion_height.value == 0) {
        accordion_height.value = "auto";
        openState.value = true;
      } else {
        accordion_height.value = 0;
        openState.value = false;
      }
    }

    return { toggleAccordion, openState, accordion_height };
  },
};
</script>

<style>
.accordion {
  width: 100%;
}
.accordion_header {
  border-bottom: 1px solid #000;
  padding: 10px;
  cursor: pointer;
  user-select: none;
  background: rgb(230, 230, 230);
  font-family: "Unika Bold";
}
.accordion_header_icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  float: left;
}
.accordion_header_icon:after {
  content: "+";
}
.accordion_header_icon.open:after {
  content: "-";
}
.accordion_content {
  height: 0;
  overflow: hidden;
  border: 1px solid rgb(238, 238, 238);
}
</style>