<template>
  <div class="toggle_container">
    <label class="toggle_label">{{ label }}</label>
    <div class="toggle">
      <input type="radio" :id="UUID_left" v-model="$store.state[bindTo.parent][bindTo.key]" v-bind:value="false" @click="updateData" />
      <label :for="UUID_left">{{ labelOff }}</label>
      <input type="radio" :id="UUID_right" v-model="$store.state[bindTo.parent][bindTo.key]" v-bind:value="true" @click="updateData" />
      <label :for="UUID_right">{{ labelOn }}</label>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { getUID } from "../../../utils/utils.js";

export default {
  props: ["onComplete", "bindTo", "labelOff", "labelOn", "label"],
  name: "Toggle",
  setup(props) {
    const store = useStore();

    // Generate unique ID for component
    let uid = getUID();
    let UUID_left = "switch_left-" + uid;
    let UUID_right = "switch_right-" + uid;

    // update VueX store data
    function updateData(event) {
      this.store.dispatch("updateState", {
        parent: props.bindTo.parent,
        key: props.bindTo.key,
        value: event.target.value,
      });

      // Trigger onComplete function (if present)
      if (props.onComplete != undefined) props.onComplete();
    }

    return { store, updateData, UUID_left, UUID_right };
  },
};
</script>

<style>
.toggle_label {
  user-select: none;
  cursor: pointer;
}
.toggle_container {
  font-family: "Unika Regular";
  font-size: 16px;
  flex: 1;
}
.toggle {
  margin-top: 3px;
}
.toggle input {
  clip: rect(0, 0, 0, 0);
  height: 0px;
  width: 0px;
  border: 0;
  display: none;
  overflow: hidden;
}
.toggle input:checked + label {
  background: #000;
  color: #fff;
}
.toggle label {
  display: inline-block;
  width: 35%;
  background-color: #f0efef;
  color: #000;
  text-align: center;
  font-size: 14px;
  padding: 1px 6px;
  transition: all 0.1s ease-in-out;
  user-select: none;
}
.toggle label:hover {
  cursor: pointer;
}
</style>