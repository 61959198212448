<template>
  <div class="filter level-filter level-req">
    <div id="rangeSlider" class="range-slider">
      <label>{{ label }}</label>
      <div class="range-group">
        <input class="range-input" :value="$store.state[min_bindTo.parent][min_bindTo.key]" :min="min" :max="max" type="range" @input="updateMinData" />
        <input class="range-input" :value="$store.state[max_bindTo.parent][max_bindTo.key]" :min="min" :max="max" type="range" @input="updateMaxData" />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  props: ["onComplete", "max_bindTo", "min_bindTo", "min", "max", "label"],
  name: "RangeSlider",
  setup(props) {
    const store = useStore();

    // update VueX store data
    function updateMinData(event) {
      this.store.dispatch("updateState", {
        parent: props.min_bindTo.parent,
        key: props.min_bindTo.key,
        value: event.target.value,
      });

      // Trigger onComplete function (if present)
      if (props.onComplete != undefined) props.onComplete();
    }

    function updateMaxData(event) {
      // update VueX store data
      this.store.dispatch("updateState", {
        parent: props.max_bindTo.parent,
        key: props.max_bindTo.key,
        value: event.target.value,
      });

      // Trigger onComplete function (if present)
      if (props.onComplete != undefined) props.onComplete();
    }

    return { store, updateMinData, updateMaxData };
  },
};
</script>

<style lang="scss">
.range-slider {
  width: 300px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  font-family: "Unika Regular";
  font-size: 16px;

  label {
    font-family: "Unika Regular";
    font-size: 16px;
    color: #000;
    user-select: none;
  }

  .range-group {
    position: relative;
    flex: 0 0 100%;
    height: 27px;

    .range-input {
      position: absolute;
      left: 0;
      bottom: 0;
      margin-bottom: 0;
      -webkit-appearance: none; // vendor-prefix exception
      width: 100%;
      border-bottom: 0;
      background: transparent;
      pointer-events: none;

      &:focus {
        outline: 0;
      }

      // vendor prefix exceptions - must be kept seperate to work
      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        // animate: .2s;
        animation: 0.2s;
        background: #000;
        border-radius: 1px;
        box-shadow: none;
        border: 0;
        pointer-events: none;
      }

      &::-webkit-slider-thumb {
        z-index: 2;
        position: relative;
        // box-shadow: 0px 0px 0px #000;
        // border: 1px solid #2497e3;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background: #000;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -7px;
        pointer-events: all !important;
      }

      &::-moz-range-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        // animate: .2s;
        animation: 0.2s;
        background: #000;
        border-radius: 1px;
        box-shadow: none;
        border: 0;
      }

      &::-moz-range-thumb {
        z-index: 2;
        position: relative;
        box-shadow: 0px 0px 0px #000;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background: #000;
        cursor: pointer;
        pointer-events: all !important;
      }

      &::-ms-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        // animate: .2s;
        animation: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
      }

      &::-ms-fill-lower,
      &::-ms-fill-upper {
        background: #3faffa;
        border-radius: 1px;
        box-shadow: none;
        border: 0;
      }

      &::-ms-thumb {
        z-index: 2;
        position: relative;
        // box-shadow: 0px 0px 0px #000;
        // border: 1px solid #2497e3;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background: #3faffa;
        cursor: pointer;
        pointer-events: all !important;
      }
    }
  }
}

.range-slider,
.filter {
  //   margin: 0 auto 30px;
  max-width: 100%;
}
</style>