<template>
  <button class="button" :class="{ black: theme === 'black', recording: theme === 'recording' }">{{ title }}</button>
</template>

<script>
export default {
  // # Example usage:
  // <Button title="Save poster" @click="savePoster" />

  props: ["title", "theme"],
  name: "Button",
  setup() {},
};
</script>

<style>
.button {
  font-family: "Unika Regular";
  font-size: 16px;
  color: #000;
  background: transparent;
  border: 1px solid #000;
  padding: 6px 30px;
  cursor: pointer;
  transition: 0.25s;
  user-select: none;
}
.button:hover {
  background: #000;
  color: #fff;
}
.button.black {
  background: #000;
  color: #fff;
}
.button.black:hover {
  opacity: 0.8;
}

.button.recording {
  opacity: 1;
  background: #ff0000;
  color: #fff;
  border: #fff;
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}
</style>