<template>
    <input
      autocomplete="off"
      class="numberInput"
      type="number"
      :value="$store.state[bindTo.parent][bindTo.key]"
      @input="updateData"
    />
  <label>{{ label }}</label>
</template>

<script>
import { useStore } from "vuex";

export default {
  props: ["onComplete", "bindTo", "label"],
  name: "NumberInput",
  setup(props) {
    const store = useStore();

    // update VueX store data
    function updateData(event) {
      this.store.dispatch("updateState", {
        parent: props.bindTo.parent,
        key: props.bindTo.key,
        value: event.target.value,
      });

      // Trigger onComplete function (if present)
      if (props.onComplete != undefined) props.onComplete();
    }

    return { store, updateData };
  },
};
</script>

<style lang="scss">
.numberInput {
  font-family: "Unika Regular";
  font-size: 16px;
  max-width: 50px;
  margin: -3px 10px 0 0;
}
.numberInput:focus {
  outline: none;
}

label {
  font-family: "Unika Regular";
  font-size: 16px;
  color: #000;
  user-select: none;
}
</style>