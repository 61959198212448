import { map, radians, drawImageProp } from '../utils/utils.js';

let canvasManager, ctx, store, base, text;

const createPoster = (_canvasManager, _ctx, _store, _base) => {
    // Set "global" variables
    canvasManager = _canvasManager;
    ctx = _ctx;
    store = _store;
    base = _base;
    canvasManager.imageObjects = [];


    if (!canvasManager.generatingNewColors) {
        drawBackground();
        drawBottomImages();
        try {
            drawBaseGrid();
        }
        catch (err) {
            alert("Bitte beachten Sie: Es gab einen Fehler beim Export. Wahrscheinlich ist Ihr Browser nicht in der Lage so hohe Auflösungen zu exportieren. Lösung: Nutzen Sie Google Chrome oder verkleinern Sie die Größe Ihres Browser-Fensters.")

            // Return to original scale
            store.dispatch("updateState", { parent: "canvas", key: "scale", value: 1 });
            canvasManager.resize();
            canvasManager.startAnimationLoop();

            // Hide modal
            store.dispatch("updateState", { parent: "images", key: "generatingGraphic", value: false });
        }
        drawMiddleImages();
        drawImageDecoration();
        drawAccentGrid();
        drawTopImages();
        drawGraphicalDecoration();
    }
}



// BACKGROUND ---------------------------------------------------------------------------------------------
function drawBackground() {
    // Fill background with color
    ctx.fillStyle = store.state.design.backgroundColor;
    ctx.fillRect(0, 0, canvasManager.width, canvasManager.height);

    // If a background image is present, draw it centered at full height
    if (store.state.design.backgroundImage != null) {
        drawImageProp(ctx, store.state.design.backgroundImage.image, 0, 0, canvasManager.width, canvasManager.height)
    }
}





// IMAGE DECORATION ELEMENTS ---------------------------------------------------------------------------------------------
function drawImageDecoration() {
    if (store.state.design.decorationImages) {
        let border = store.state.grid.border * canvasManager.width * 0.01;

        for (let index in store.state.design.decorationImages_grid) {
            let currentImage = store.state.design.decorationImages_grid[index];

            // If there is no background image, draw all images
            if (store.state.design.backgroundImage == null) {
                drawImageOnCanvas();
            } else {
                // Only draw this image if it is not already used as the background image
                if (currentImage.name != store.state.design.backgroundImage.name) {
                    drawImageOnCanvas();
                }
            }

            function drawImageOnCanvas() {
                // Determine image dimension based on the actual canvas dimensions
                let dimensions = {
                    width: map(currentImage.dimensions.width, 0, 10, Math.floor(currentImage.dimensions.width * canvasManager.width * (store.state.design.decorationImages_size_min * 0.005)), Math.floor(currentImage.dimensions.width * canvasManager.width * (store.state.design.decorationImages_size_max * 0.0005))),
                    height: 0
                }
                dimensions.height = dimensions.width * currentImage.aspect;

                // Determine the position of the image , based on the available width and height
                let availableWidth = canvasManager.width - (border + dimensions.width);
                let availableHeight = canvasManager.height - (border + dimensions.height);
                let position = {
                    x: Math.floor(map(currentImage.position.x, 0, 1, border, availableWidth)),
                    y: Math.floor(map(currentImage.position.y, 0, 1, border, availableHeight)),
                }

                // Reference to this image object which will be used to handle mouse interactions
                let imageObject = {
                    reference: currentImage,
                    dimensions: dimensions,
                    position: position
                };

                // Draw the image
                try {
                    ctx.drawImage(currentImage.image, position.x, position.y, dimensions.width, dimensions.height);
                } catch (e) {
                    // No error handling necessary, just skip this instance
                }
            }
        }
    }
}


// GRAPHICAL DECORATION ELEMENTS ---------------------------------------------------------------------------------------------
function drawGraphicalDecoration() {
    if (store.state.design.decoration) {
        let border = store.state.grid.border * canvasManager.width * 0.01;

        for (let index in store.state.design.decoration_grid) {
            let decoration = store.state.design.decoration_grid[index];

            // Determine image dimension based on the actual canvas dimensions
            let decorativeElementSize = map(decoration.dimensions, 0, 10, Math.floor(decoration.dimensions * canvasManager.width * (store.state.design.decoration_size_min * 0.001)), Math.floor(decoration.dimensions * canvasManager.width * (store.state.design.decoration_size_max * 0.0001)));
            let dimensions = {
                width: decorativeElementSize,
                height: decorativeElementSize
            }

            // Determine the position of the decorative element , based on the available width and height
            let availableWidth = canvasManager.width - (border + dimensions.width);
            let availableHeight = canvasManager.height - (border + dimensions.height);
            let position = {
                x: Math.floor(map(decoration.position.x, 0, 1, border, availableWidth)),
                y: Math.floor(map(decoration.position.y, 0, 1, border, availableHeight)),
            }

            // Draw the thing
            ctx.fillStyle = decoration.color;

            ctx.beginPath();

            // Rectangle
            if (decoration.type == 0) {
                ctx.rect(position.x, position.y, dimensions.width, dimensions.height);
            }
            // Circle
            if (decoration.type == 1) {
                ctx.arc(position.x + dimensions.width / 2, position.y + dimensions.height / 2, dimensions.width / 2, 0, 2 * Math.PI)
            }
            // Triangle
            if (decoration.type == 2) {
                ctx.save();
                ctx.translate(position.x + dimensions.width / 2, position.y + dimensions.height / 2)
                ctx.rotate(radians(90 * decoration.rotation));
                ctx.translate(-(position.x + dimensions.width / 2), -(position.y + dimensions.height / 2))

                ctx.moveTo(position.x, position.y + dimensions.height);
                ctx.lineTo(position.x + dimensions.width, position.y + dimensions.height);
                ctx.lineTo(position.x + dimensions.width / 2, position.y);
                ctx.closePath();

                ctx.restore();
            }
            ctx.fill();
        }
    }
}



// IMAGES ---------------------------------------------------------------------------------------------
function drawBottomImages() {
    drawImagesFromArray(0);
}

function drawMiddleImages() {
    drawImagesFromArray(1);
}

function drawTopImages() {
    drawImagesFromArray(2);
}

function drawImagesFromArray(arrayReference) {
    let border = store.state.grid.border * canvasManager.width * 0.01;

    for (let index in store.state.design.image_grid) {
        if (store.state.design.image_grid[index].arrayPosition == arrayReference) {
            let currentImage = store.state.design.image_grid[index];

            // Determine image dimension based on the actual canvas dimensions
            let dimensions = {
                width: map(currentImage.dimensions.width, 0, 15, Math.floor(currentImage.dimensions.width * canvasManager.width * (store.state.design.alpha_imageSize_min * 0.01)), Math.floor(currentImage.dimensions.width * canvasManager.width * (store.state.design.alpha_imageSize_max * 0.001))),
                height: 0
            }
            dimensions.height = dimensions.width * currentImage.aspect;

            // Determine the position of the image , based on the available width and height
            let availableWidth = canvasManager.width - (border + dimensions.width);
            let availableHeight = canvasManager.height - (border + dimensions.height);
            let position = {
                x: Math.floor(map(currentImage.position.x, 0, 1, border, availableWidth)),
                y: Math.floor(map(currentImage.position.y, 0, 1, border, availableHeight)),
            }

            // Reference to this image object which will be used to handle mouse interactions
            let imageObject = {
                reference: currentImage,
                dimensions: dimensions,
                position: position
            };
            canvasManager.imageObjects.push(imageObject);

            // Draw the image
            try {
                ctx.drawImage(currentImage.image, position.x, position.y, dimensions.width, dimensions.height);
            } catch (e) {
                // No error handling necessary, just skip this instance
            }
        }
    }
}




// GRIDS ---------------------------------------------------------------------------------------------
function drawBaseGrid() {
    let index = 0;
    for (let x = 0; x < store.state.grid.base_rows; x++) {
        for (let y = 0; y < store.state.grid.base_cols; y++) {
            let border = store.state.grid.border * canvasManager.width * 0.01;
            let dimensions = {
                width: (canvasManager.width - border * 2) / store.state.grid.base_rows,
                height: (canvasManager.height - border * 2) / store.state.grid.base_cols,
            }
            let position = {
                x: border + x * dimensions.width,
                y: border + y * dimensions.height,
            }

            ctx.save();
            ctx.fillStyle = store.state.design.grid_base_color[index].computedColor;

            ctx.translate(position.x + dimensions.width / 2, position.y + dimensions.height / 2)
            ctx.rotate(radians(store.state.grid.base_rotation));
            ctx.translate(-(position.x + dimensions.width / 2), -(position.y + dimensions.height / 2))

            ctx.beginPath();
            // Rectangle
            if (store.state.design.grid_base_color[index].shapeType == 1) {
                ctx.rect(position.x, position.y, dimensions.width, dimensions.height);
            }
            // Circle
            if (store.state.design.grid_base_color[index].shapeType == 2) {
                ctx.arc(position.x + dimensions.width / 2, position.y + dimensions.height / 2, dimensions.width / 2, 0, 2 * Math.PI)
            }
            // Triangle
            if (store.state.design.grid_base_color[index].shapeType == 3) {
                ctx.moveTo(position.x, position.y + dimensions.height);
                ctx.lineTo(position.x + dimensions.width, position.y + dimensions.height);
                ctx.lineTo(position.x + dimensions.width / 2, position.y);
                ctx.closePath();
            }
            ctx.fill();

            ctx.restore();

            index++;
        }
    }
}




function drawAccentGrid() {
    let index = 0;
    for (let x = 0; x < store.state.grid.accent_rows; x++) {
        for (let y = 0; y < store.state.grid.accent_cols; y++) {
            let border = store.state.grid.border * canvasManager.width * 0.01;
            let dimensions = {
                width: (canvasManager.width - border * 2) / store.state.grid.accent_rows,
                height: (canvasManager.height - border * 2) / store.state.grid.accent_cols,
            }
            let position = {
                x: border + x * dimensions.width,
                y: border + y * dimensions.height,
            }

            ctx.save();
            ctx.fillStyle = store.state.design.grid_accent_color[index].computedColor;

            ctx.translate(position.x + dimensions.width / 2, position.y + dimensions.height / 2)
            ctx.rotate(radians(store.state.grid.accent_rotation));
            ctx.translate(-(position.x + dimensions.width / 2), -(position.y + dimensions.height / 2))

            ctx.beginPath();
            // Rectangle
            if (store.state.design.grid_accent_color[index].shapeType == 1) {
                ctx.rect(position.x, position.y, dimensions.width, dimensions.height);
            }
            // Circle
            if (store.state.design.grid_accent_color[index].shapeType == 2) {
                ctx.arc(position.x + dimensions.width / 2, position.y + dimensions.height / 2, dimensions.width / 2, 0, 2 * Math.PI)
            }
            // Triangle
            if (store.state.design.grid_accent_color[index].shapeType == 3) {
                ctx.moveTo(position.x, position.y + dimensions.height);
                ctx.lineTo(position.x + dimensions.width, position.y + dimensions.height);
                ctx.lineTo(position.x + dimensions.width / 2, position.y);
                ctx.closePath();
            }

            ctx.fill();
            ctx.restore();

            index++;
        }
    }
}

export { createPoster }