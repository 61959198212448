<template>
  <div class="slider_container">
    <label class="slider_label">{{ label }}</label>
    <input type="range" :min="min" :max="max" :value="$store.state[bindTo.parent][bindTo.key]" @input="updateData" class="slider" />
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  // # Example usage:
  // <Slider :label="'Animation Speed'" :min="0" :max="100" :bindTo="{ parent: 'design', key: 'animationSpeed' }" />

  props: ["onComplete", "min", "max", "bindTo", "label"],
  name: "Slider",
  setup(props) {
    const store = useStore();

    // update VueX store data
    function updateData(event) {
      this.store.dispatch("updateState", {
        parent: props.bindTo.parent,
        key: props.bindTo.key,
        value: event.target.value,
      });

      // Trigger onComplete function (if present)
      if (props.onComplete != undefined) props.onComplete();
    }

    return { store, updateData };
  },
};
</script>

<style>
.slider_container {
  flex: 1;
}
.slider_label {
  font-family: "Unika Regular";
  font-size: 16px;
  color: #000;
  user-select: none;
}
.slider {
  width: 100%;
  margin-top: 15px;
  display: block;
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  background: #000;
  outline: none;
  cursor: pointer;
}

/*Chrome*/
input[type="range"]::-webkit-slider-thumb {
  width: 16px;
  -webkit-appearance: none;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  background: #000;
}

/*Firefox*/
.slider::-moz-range-thumb {
  cursor: pointer;
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  border: 0;
  border-radius: 50%;
  background-color: #000;
  box-shadow: 0 0 0 transparent;
  transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1),
    box-shadow 0.15s cubic-bezier(0.25, 0, 0.25, 1);
  transform-origin: center;
}
.slider::-moz-range-track {
  width: 100%;
  height: 2px;
  background-color: #000;
}
</style>