<template>
  <div ref="artboard_parentContainer" id="artboard_container">
    <canvas
      :width="$store.state.canvas.width"
      :height="$store.state.canvas.height"
      id="vueCanvas"
    ></canvas>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";

import CanvasManager from "../managers/CanvasManager";
let canvasManager = ref(null); // reference to the canvasManager that handles canvas operations
export { canvasManager }; // export canvasManager reference to expose it to other components

export default {
  name: "Artboard",
  components: {},
  setup() {
    // Variables
    // ---------------------------------------------------------------------------------------------
    const store = useStore();
    const artboard_parentContainer = ref(null); // reference to the parent container of the canvas within the DOM

    // Prevent the context menu of the canvas to appear when a user right-clicks
    onMounted(() => {
      document.getElementById("vueCanvas").oncontextmenu = () => false;
    });

    // Watch for all images to be preloaded. Once that is the case, determine the image placement on the poster
    watch(
      () => store.getters.imagesArePreloaded,
      function (allImagesLoaded) {
        if (allImagesLoaded == true) {
          canvasManager.determineImagePlacement();
          canvasManager.determineImageDecoration();
        }
      }
    );

    // Set up canvas
    // ---------------------------------------------------------------------------------------------
    function mountArtboard() {
      // Bind resize event
      window.addEventListener("resize", resize.bind(this));

      // Create new instance of the canvasManager
      canvasManager = new CanvasManager({
        parentContainer: artboard_parentContainer.value,
      });
    }

    // Trigge resize event
    function resize() {
      canvasManager.resize();
    }

    // Hook-events && helper functions
    // ---------------------------------------------------------------------------------------------
    onMounted(() => {
      mountArtboard();
    });

    return { artboard_parentContainer, store };
  },
};
</script>

<style>
#artboard_container {
  /* width: 66%; */
  width: calc(100vw - 450px);
  display: flex;
  align-items: center;
  justify-content: center;
}
#vueCanvas {
  /* -webkit-box-shadow: 0px 0px 3vw 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 3vw 10px rgba(0, 0, 0, 0.15); */
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  margin: 4vw;
}
</style>