<template>
  <div class="imageSelector">
    <div
      class="imageSelector_imageContainer"
      v-for="(image, index) in $store.state[bindTo.parent][bindTo.key]"
      :key="index"
      :data-update="updateKey"
    >
      <span
        @click="removeImage"
        :data-store="bindTo.key"
        :data-name="image.name"
        class="imageSelector_remove"
        >&#x2715;</span
      >
      <img :src="image.image.src" class="imageSelector_image" />
      <div
        :class="{
          imageSelectorActive: $store.state.design.backgroundImage === image,
        }"
        @click="selectAsBackgroundImage"
        :data-store="bindTo.key"
        :data-name="image.name"
        class="imageSelector_button"
      >
        Hintergrund
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { canvasManager } from "../../Artboard.vue";

export default {
  name: "ImageSelector",
  props: ["onComplete", "bindTo", "updateKey"],

  setup(props) {
    const store = useStore();

    function removeImage(event) {
      // Remove image from image_grid array
      store.dispatch("removeFromArray", {
        parent: "design",
        key: "image_grid",
        arrayKey: "name",
        value: event.target.getAttribute("data-name"),
      });

      // Check if this image is currently the active background image
      if (store.state.design.backgroundImage != null) {
        if (
          store.state.design.backgroundImage.name ==
            event.target.getAttribute("data-name") &&
          store.state.design.backgroundImage.store ==
            event.target.getAttribute("data-store")
        ) {
          this.store.dispatch("updateState", {
            parent: "design",
            key: "backgroundImage",
            value: null,
          });
        }
      }

      // Remove clicked element from base image array
      store.dispatch("removeFromArray", {
        parent: props.bindTo.parent,
        key: props.bindTo.key,
        arrayKey: "name",
        value: event.target.getAttribute("data-name"),
      });
    }

    function selectAsBackgroundImage(event) {
      canvasManager.setBackgroundImage({
        name: event.target.getAttribute("data-name"),
        store: event.target.getAttribute("data-store"),
      });
    }

    return { store, removeImage, selectAsBackgroundImage };
  },
};
</script>

<style>
.imageSelector {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 140px;
  overflow-x: auto;
  overflow-y: hidden;
}
.imageSelector_imageContainer {
  padding: 5% 6% 5% 4%;
  position: relative;
  user-select: none;
}
.imageSelector_imageContainer img {
  width: auto;
  max-height: 120px;
  user-select: none;
}
.imageSelector_remove {
  cursor: pointer;
  position: absolute;
  background: rgb(247, 247, 247);
  color: #000;
  width: 16px;
  height: 16px;
  text-align: center;
  font-size: 14px;
  padding: 5px 5px 5px 5px;
  font-family: Arial;
  margin-left: -10px;
  margin-top: -10px;
  border-radius: 50%;
  z-index: 100;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
  user-select: none;
}
.imageSelector_remove:hover {
  background: #000;
  color: #fff;
}

.imageSelector_remove:hover + .imageSelector_image {
  opacity: 0.5;
}

.imageSelector_imageContainer:hover .imageSelector_button {
  opacity: 1;
  transition: opacity 0.25s;
}
.imageSelector_button {
  transition: opacity 0.25s;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  color: #000;
  font-size: 12px;
  position: absolute;
  padding: 3px 6px 3px 6px;
  cursor: pointer;
  opacity: 0;
  top: 0;
  left: 40px;
  /* right: 0; */
  margin: 7px 12px 0 0;
  border-radius: 10px;
}
.imageSelector_button:hover {
  background: #000;
  color: #fff;
}
.imageSelectorActive {
  opacity: 1;
  background: #000;
  color: #fff;
}
</style>