import { createStore, storeKey } from 'vuex'
import { canvasManager } from '@/components/Artboard.vue';

export default createStore({
  state: {
    generator: 'BSG Collage Generator by Patrik Hübner',
    version: process.env.VUE_APP_VERSION || '0',
    enableStats: false,
    loadOperations: {
      forceUpdate: 0,
    },
    frameCount: 0,
    folders: {
      base: process.env.BASE_URL,
      fonts: 'fonts/',
    },
    canvas: {
      id: null,
      ctx: null,
      width: 0,
      height: 0,
      retina: null,
      scale: 1,
    },
    colors: {
      grundfarben: ['#292929', '#434343', '#696969', '#989898', '#B4B4B4', '#D8D8D8', '#EAEAEA', '#707070'],
      akzenteKomplett: [
        '#D51130', '#3598CB', '#A0D168', '#82CEDE', '#E3DB30', '#92DBC8', '#FC9A7D', '#51B078', '#DCBD62', '#E8586E', '#F9642D', '#20A6D4', '#FA7272',
        '#D51130', '#7626AE', '#34DCC8', '#FA2CDB', '#FB561E', '#FC9E6E', '#ACC9C9', '#FDCD2F', '#026CFB', '#656778', '#FB7E61', '#20A6D4', '#FA7272',
        '#D51130', '#D35F1A', '#E8AC28', '#DE802C', '#208E33', '#1F662A', '#5BA433', '#A4C46B', '#2377AE', '#329ECE', '#8CC7E8', '#1A2C5D', '#E7AAC6'
      ],
      akzente: [
        ['#D51130', '#3598CB', '#A0D168', '#82CEDE', '#E3DB30', '#92DBC8', '#FC9A7D', '#51B078', '#DCBD62', '#E8586E', '#F9642D', '#20A6D4', '#FA7272'],
        ['#D51130', '#7626AE', '#34DCC8', '#FA2CDB', '#FB561E', '#FC9E6E', '#ACC9C9', '#FDCD2F', '#026CFB', '#656778', '#FB7E61', '#20A6D4', '#FA7272'],
        ['#D51130', '#D35F1A', '#E8AC28', '#DE802C', '#208E33', '#1F662A', '#5BA433', '#A4C46B', '#2377AE', '#329ECE', '#8CC7E8', '#1A2C5D', '#E7AAC6']
      ],
    },
    grid: {
      border: 10,

      base_rows: 5,
      base_cols: 7,
      base_frequency: 60, // percentage of how often a base element is drawn
      base_rotation: 0,
      base_rectangle: true,
      base_circle: false,
      base_triangle: false,

      accent_rows: 5,
      accent_cols: 5,
      accent_frequency: 30, // percentage of how often an accent element is drawn
      accent_rotation: 0,
      accent_rectangle: true,
      accent_circle: false,
      accent_triangle: false,
    },
    images: {
      preloaded: false, // Determine if all images have been preloaded
      generatingGraphic: false,

      demo_normal: ['dave-ruck-pxjN_6Z_3x8-unsplash.jpg', 'mike-dennler-T9CktnMI8TQ-unsplash.jpg', 'AdobeStock_85576561.jpg'],
      demo_alpha: ['Camera.png', 'Starfish.png', 'Clock.png', 'Watch.png'],
      normal: [],
      alpha: [],
    },
    design: {
      decoration: true,
      decoration_grid: [],
      decoration_min: 5,
      decoration_max: 10,
      decoration_size_min: 10,
      decoration_size_max: 60,

      decorationImages: false,
      decorationImages_grid: [],
      decorationImages_min: 0,
      decorationImages_max: 0,
      decorationImages_size_min: 10,
      decorationImages_size_max: 60,

      backgroundColor: null,
      backgroundImage: null,

      accentColor: null,
      grid_base_color: [],
      grid_accent_color: [],
      image_grid: [],
      alpha_imageSize_min: 10,
      alpha_imageSize_max: 40,
    },
    export: {
      exportAmount: 5,
      imageAmount_min: 3,
      imageAmount_max: 5,
      files: [],
    }
  },
  getters: {
    // helpers to get data from state
    appVersion: (state) => {
      return state.version.replace(/["]+/g, '')
    },
    imagesArePreloaded: (state) => {
      return state.images.preloaded;
    },
    normalImagesUpdated: (state) => {
      return state.images.normal.length;
    },
    getCurrentState: (state) => {
      let stateToSave = JSON.stringify(state);
      return stateToSave;
    },
  },
  mutations: {
    // synchronous
    // change/modify values of state

    ANIMATION_INCREASE_FRAMECOUNT(state) {
      state.frameCount++;
    },

    UPDATE_STATE(state, { parent, key, value }) {
      state[parent][key] = value;
    },

    PUSH_TO_ARRAY(state, { parent, key, value }) {
      state[parent][key].push(value);
    },

    REMOVE_FROM_ARRAY(state, { parent, key, arrayKey, value }) {
      state[parent][key] = state[parent][key].filter(function (item) {
        return item[arrayKey] !== value;
      });
    },

    CLEAR_ARRAY(state, { parent, key }) {
      state[parent][key] = [];
    },

    SET_STATE_FROM_FILE(state, { loadedData }) {
      // ----- STEP 1: RE-BUILD IMAGES FROM BLOBS
      function loadImagesFromJSON(jsonObject) {
        return new Promise(resolve => {
          let imagesToLoad = jsonObject.length;
          let imagesLoaded = 0;

          if (jsonObject.length == 0) {
            resolve('OK');
          }

          for (let index in jsonObject) {
            let imageObject = jsonObject[index];

            let image = new Image();
            image.src = imageObject.blob;
            image.onload = () => {
              imageObject.image = image;
              imagesLoaded++;

              if (imagesLoaded == imagesToLoad) { resolve('OK') }
            }
          }
        });
      }

      // Load and parse normal images
      loadImagesFromJSON(loadedData.images.normal).then(() => {
        // Load and parse alpha images
        loadImagesFromJSON(loadedData.images.alpha).then(() => {
          // Once both image types have been re-initialized, scan the design for all occurences of references and push the images into those as well

          // Alpha images
          pushImagesToReference(loadedData.design.image_grid, loadedData.images.alpha);
          // Normal images
          pushImagesToReference(loadedData.design.decorationImages_grid, loadedData.images.normal);
        });
      })


      // ----- STEP 2: FIND ALL IMAGES IN THE DESIGN GRIDS AND UPDATE THEIR IMAGES FROM THE ONES LOADED VIA THE BLOB
      function pushImagesToReference(imageSection, referenceSection) {
        for (let index in imageSection) {
          let imageObject = imageSection[index];
          let referenceObject = referenceSection.find(img => img.name === imageObject.name);
          imageObject.image = referenceObject.image;
        }
      }


      // ----- STEP 3: UPDATE THE BACKGROUND IMAGE (IF PRESENT)
      function loadAndParseBackgroundImage() {
        return new Promise(resolve => {
          let image = new Image();
          image.src = loadedData.design.backgroundImage.blob;
          image.onload = () => {
            loadedData.design.backgroundImage.image = image;
            resolve('OK');
          }
        });
      }
      if (loadedData.design.backgroundImage != null) {
        loadAndParseBackgroundImage().then(() => {
          finalizeLoadProcess(this);
        });
      } else {
        finalizeLoadProcess(this);
      }


      // ----- STEP 4: REPLACE THE STATE WITH OUR LOADED STATE
      function finalizeLoadProcess(ref) {
        ref.replaceState(loadedData);
        canvasManager.resize();
        canvasManager.forceUpdate();
      }
    }

  },
  actions: {
    // asynchronous
    // commit mutation

    animation_increaseFrameCount({ commit }) {
      commit('ANIMATION_INCREASE_FRAMECOUNT');
    },

    updateState({ commit }, { parent, key, value }) {
      commit('UPDATE_STATE', { parent, key, value });
    },

    pushToArray({ commit }, { parent, key, value }) {
      commit('PUSH_TO_ARRAY', { parent, key, value });
    },

    removeFromArray({ commit }, { parent, key, arrayKey, value }) {
      commit('REMOVE_FROM_ARRAY', { parent, key, arrayKey, value });
    },

    clearArray({ commit }, { parent, key }) {
      commit('CLEAR_ARRAY', { parent, key });
    },

    setStateFromFile({ commit }, { loadedData }) {
      commit('SET_STATE_FROM_FILE', { loadedData });
    }

  },
  modules: {
  }
})
