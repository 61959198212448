<template>
  <div class="checkBox_spacing"></div>
  <div @click="updateData" :data-value="$store.state[bindTo.parent][bindTo.key]" class="checkBox_svg" :class="{ checkBox_svg_active: $store.state[bindTo.parent][bindTo.key]}">
    <slot></slot>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  props: ["onComplete", "bindTo"],
  name: "Slider",
  setup(props) {
    const store = useStore();

    // update VueX store data
    function updateData(event) {
      let newState = null;
      if (event.target.getAttribute("data-value") == "true") {
        newState = false;
      }
      if (event.target.getAttribute("data-value") == "false") {
        newState = true;
      }
      this.store.dispatch("updateState", {
        parent: props.bindTo.parent,
        key: props.bindTo.key,
        value: newState,
      });

      // Trigger onComplete function (if present)
      if (props.onComplete != undefined) props.onComplete();
    }

    return { store, updateData };
  },
};
</script>

<style>
.checkBox_spacing {
  margin: 0 20px 0 20px;
}
.checkBox_svg {
  display: block;
}
.checkBox_svg svg {
  fill: #e6e6e6;
  width: 100%;
  pointer-events: none;
}
.checkBox_svg:hover {
  cursor: pointer;
}
.checkBox_svg_active:hover svg {
  fill: #000 !important;
}
.checkBox_svg:hover svg {
  fill: rgb(110, 110, 110);
  width: 100%;
}
.checkBox_svg_active svg {
  fill: #000;
}
</style>