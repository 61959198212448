<template>
  <div class="navigation_hamburger_button" id="toggle" @click="toggleMenu"><span class="navigation_button_top"></span><span class="navigation_button_middle"></span><span class="navigation_button_bottom"></span></div>
  <div class="navigation_overlay" id="navigation_overlay">
    <!-- Primary navigation -->
    <nav class="navigation_overlay_menu">
      <ul>
        <li><a href="#">Hilfe &amp; Tips</a></li>
        <li><a href="#">&Uuml;ber dieses Projekt</a></li>
      </ul>
    </nav>

  </div>
</template>

<script>
export default {
  setup() {
    function toggleMenu() {
      document.getElementById("toggle").classList.toggle("active");
      document.getElementById("navigation_overlay").classList.toggle("open");
    }

    return { toggleMenu };
  },
};
</script>

<style>
.credits {
  margin-top: 100px;
  font-size: 10px;
}
.credits a,
.credits a:visited {
  color: #000;
}
.credits a:hover {
  text-decoration: none;
}
.navigation_hamburger_button {
  position: fixed;
  top: 60px;
  right: 51px;
  height: 28px;
  width: 26px;
  cursor: pointer;
  z-index: 100;
  transition: opacity 0.25s ease;
  display: block;
  z-index: 1100;
}
.navigation_hamburger_button:hover {
  opacity: 0.7;
}
.navigation_hamburger_button.active .navigation_button_top {
  transform: translateY(11px) translateX(0) rotate(45deg);
  background: #000;
}
.navigation_hamburger_button.active .navigation_button_middle {
  opacity: 0;
  background: #000;
}
.navigation_hamburger_button.active .navigation_button_bottom {
  transform: translateY(-11px) translateX(0) rotate(-45deg);
  background: #000;
}
.navigation_hamburger_button span {
  background: #000;
  border: none;
  height: 2px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.35s ease;
  cursor: pointer;
}
.navigation_hamburger_button span:nth-of-type(2) {
  top: 11px;
}
.navigation_hamburger_button span:nth-of-type(3) {
  top: 22px;
}

.navigation_overlay {
  position: fixed;
  background: #f6f6f6;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  z-index: 1000;
}
.navigation_overlay.open {
  opacity: 1;
  visibility: visible;
  height: 100%;
}
.navigation_overlay.open li {
  animation: fadeInRight 0.5s ease forwards;
  animation-delay: 0.35s;
}
.navigation_overlay.open li:nth-of-type(2) {
  animation-delay: 0.4s;
}
.navigation_overlay.open li:nth-of-type(3) {
  animation-delay: 0.45s;
}
.navigation_overlay.open li:nth-of-type(4) {
  animation-delay: 0.5s;
}
.navigation_overlay nav {
  position: relative;
  top: 50%;
  padding: 0 0 0 5%;
  transform: translateY(-50%);
  font-size: 2.5rem;
  font-family: "Unika Regular";
}
.navigation_overlay ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  height: 100%;
}
.navigation_overlay ul li {
  display: block;
  position: relative;
  opacity: 0;
}
.navigation_overlay ul li a {
  display: block;
  position: relative;
  color: #000;
  text-decoration: none;
  overflow: hidden;
}
.navigation_overlay ul li a:hover:after,
.navigation_overlay ul li a:focus:after,
.navigation_overlay ul li a:active:after {
  width: 200%;
}
.navigation_overlay ul li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 0%;
  transform: translateX(-50%);
  height: 3px;
  background: #000;
  transition: 0.35s;
}

nav.navigation_overlay_menu_small {
  font-size: 1rem;
}
nav.navigation_overlay_menu_small ul li a:after {
  height: 1px;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@media screen and (max-width: 640px) {
  .navigation_overlay nav {
    margin-top: 30px;
  }
  .credits {
    margin-top: 50px;
  }
}
</style>