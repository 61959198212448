<template>
  <div class="colorDropdown">
    <div class="colorDropdown_inner" @click="toggleVisibility" :style="{'background-color': $store.state[bindTo.parent][bindTo.key]}">
    </div>

    <div v-if="isVisible" class="colorDropdown_menu">
      <div @click="selectColor" :data-color="color" :style="{'background-color': color}" class="colorDropdown_color" v-for="(color, index) in $store.state[colors.parent][colors.key]" :key="index"></div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { canvasManager } from "../../Artboard.vue";

export default {
  props: ["onComplete", "bindTo", "colors"],
  name: "ColorDropdown",
  setup(props) {
    const store = useStore();
    const isVisible = ref(null);

    // update VueX store data
    function updateData(event) {
      this.store.dispatch("updateState", {
        parent: props.bindTo.parent,
        key: props.bindTo.key,
        value: event.target.value,
      });

      // Trigger onComplete function (if present)
      if (props.onComplete != undefined) props.onComplete();
    }

    function toggleVisibility() {
      isVisible.value = !isVisible.value;
    }

    function selectColor(event) {
      this.store.dispatch("updateState", {
        parent: props.bindTo.parent,
        key: props.bindTo.key,
        value: event.target.getAttribute("data-color"),
      });
      canvasManager.determineColors({ keepAccent: true, keepBackground: true });
    }

    return { store, updateData, toggleVisibility, isVisible, selectColor };
  },
};
</script>

<style>
.colorDropdown {
  border: 1px solid #000;
  height: 30px;
  width: 100%;
  padding: 5px;
  z-index: 1000;
}
.colorDropdown_inner {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.colorDropdown_menu {
  position: absolute;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  width: 135px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;

  -webkit-box-shadow: 0px 0px 49px -11px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 49px -11px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 49px -11px rgba(0, 0, 0, 0.36);
}
.colorDropdown_color {
  height: 50px;
  width: 50%;
  cursor: pointer;
}
.colorDropdown_color:hover {
  opacity: 0.9;
}
</style>