<template>
  <div class="backdrop" @click.self="closeModal">
    <div class="modalContainer">
      <div class="modal" :class="{ sale: theme === 'sale' }">
        <slot></slot>
        <div class="actions">
          <slot name="links"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["theme"],
  methods: {
    closeModal() {
      this.$emit("closeEvent");
    },
  },
};
</script>


<style>
.modalContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
.modal {
  font-family: "Unika Regular";
  width: 400px;
  padding: 20px;
  /* margin: 100px auto; */
  background: white;
  border-radius: 10px;
  pointer-events: all;
}
.modal h1 {
  font-family: "Unika Bold";
  line-height: 1.2;
}
.modal p {
  line-height: 1.5;
}
.backdrop {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
.modal .actions {
  text-align: center;
  margin: 70px 0 20px 0;
}
.modal .actions a {
  color: #fff;
  padding: 8px 30px 8px 30px;
  border: 1px solid #eee;
  text-decoration: none;
  margin: 10px;
  background: #000;
}
.modal.sale {
  background: crimson;
  color: white;
}
.modal.sale h1 {
  color: white;
}
.modal.sale .actions {
  color: white;
}
.modal.sale .actions a {
  color: white;
}
</style>