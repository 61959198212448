<template>
  <div id="dropTarget">
    <div class="dropTarget_target" id="dropTarget_normal">
      <div class="dropTarget_content">
        <img :src="`${publicPath}images/dropTarget_normal.png`" />
        Basis-Bilder hier ablegen
      </div>
    </div>
    <div class="dropTarget_target" id="dropTarget_alpha">
      <div class="dropTarget_content">
        <img :src="`${publicPath}images/dropTarget_alpha.png`" />
        Bilder mit Alpha-Kanal hier ablegen
      </div>
    </div>
  </div>

  <div id="tooSmall">
    <div id="tooSmalFlex">
      <div id="tooSmallIcon">
        <img :src="`${publicPath}images/rotateDevice.png`" />
      </div>
      Der Bildschirm dieses Ger&auml;tes ist leider zu klein. Wenn Sie diese
      Anwendung auf einem Mobilger&auml;t betrachten, wechseln Sie bitte in den
      Portrait-Modus.
    </div>
  </div>
  <Navigation />
  <Artboard />
  <UI />

  <Modal v-if="$store.state.images.generatingGraphic">
    <div style="margin: 0 auto; width: 40%; padding-bottom: 50px">
      <img
        style="width: 100%"
        :src="`${publicPath}images/creatingGraphic.png`"
      />
    </div>
    <h1>Grafik wird f&uuml;r den Download vorbereitet</h1>
    <h3>
      Je nach Aufl&ouml;sung und Rechnergeschwindigkeit kann dies einige
      Sekunden dauern - bitte haben Sie kurz Geduld. Sobald die Grafik generiert
      wurde, können Sie ausw&auml;hlen wo Sie diese speichern wollen.
    </h3>
  </Modal>
</template>

<script>
import { canvasManager } from "./components/Artboard.vue";
import Navigation from "./components/Navigation.vue";
import Artboard from "./components/Artboard.vue";
import UI from "./components/UI/UI.vue";
import Stats from "./utils/Stats";
import Modal from "./components/UI/components/component_modalWindow.vue";
import { dragDropEvent } from "./utils/utils";
import { useStore } from "vuex";
import { onMounted } from "@vue/runtime-dom";

export default {
  name: "App",
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  components: {
    Navigation,
    Artboard,
    UI,
    Modal,
  },
  setup() {
    const store = useStore();

    // DRAG AND DROP FUNCTIONALITY ---------------------------------------------------------------------------------------------
    onMounted(() => {
      dragDropEvent(handleDrop_normal, handleDrop_alpha);
    });
    function handleDrop_normal(e) {
      handleImageDrop(e, "images", "normal");
    }
    function handleDrop_alpha(e) {
      handleImageDrop(e, "images", "alpha");
    }

    function handleImageDrop(e, parent, key) {
      // Loop over all files
      for (let file in e.dataTransfer.files) {
        // Grab the current file
        let currentFile = e.dataTransfer.files[file];
        // Make sure it has properties to continue (otherwise ignore)
        if (
          typeof currentFile.size != "undefined" &&
          (currentFile.type == "image/png" || currentFile.type == "image/jpeg")
        ) {
          // Load the file
          let reader = new FileReader();
          reader.readAsDataURL(currentFile);

          // Once the file has been read...
          reader.onload = function (event) {
            // Create new image and set src
            let image = new Image();
            image.src = event.target.result;
            // Once the file has loaded... (asynchronously)
            image.onload = () => {
              // Convert image to BLOB
              let blobCanvas = document.createElement("CANVAS");
              let blobCtx = blobCanvas.getContext("2d");
              let dataURL;
              blobCanvas.height = image.height;
              blobCanvas.width = image.width;
              blobCtx.drawImage(image, 0, 0);
              dataURL = blobCanvas.toDataURL(currentFile.type);
              blobCanvas = null;

              // Create image object
              let thisImage = {
                blob: dataURL,
                name: currentFile.name,
                image: image,
                aspect: image.height / image.width,
                grayScale: null,
              };

              // Push image to array
              store.dispatch("pushToArray", {
                parent: parent,
                key: key,
                value: thisImage,
              });

              // Push to existing design
              canvasManager.addImageToPlacement(thisImage, key);
            };
          };
        }
      }

      return false;
    }

    // STATISTICS ---------------------------------------------------------------------------------------------
    // DO: Fix double frame-rate on hot reload
    if (store.state.enableStats) initStats();
    function initStats() {
      window.stats = new Stats();
      let statsContainer = document.createElement("div");
      statsContainer.setAttribute("id", "Stats-output");
      statsContainer.appendChild(window.stats.dom);
      document.body.appendChild(statsContainer);
    }

    return { store };
  },
};
</script>

<style>
#dropTarget {
  position: fixed;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.98);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: none;
}
.dropTarget_visible {
  display: flex !important;
}
.dropTarget_target {
  width: 40%;
  text-align: center;
  border: 3px dashed #000;
  height: 70%;
  margin: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropTarget_content {
  font-size: 24px;
  padding: 50px;
}
.dropTarget_content img {
  width: 70%;
  margin-left: 15%;
  display: block;
  margin-bottom: 50px;
}
.dropTarget_active {
  background: rgba(255, 255, 255);
  filter: invert(1);
}

#tooSmall {
  position: fixed;
  z-index: 99999;
  background: #fff;
  color: #000;
  font-family: "Uni Regular";
  font-size: 14px;
  width: 90vw;
  height: 100%;
  padding: 5vh 5vw 5vh 5vw;
  text-align: center;
  display: none;
}
#tooSmalFlex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
#tooSmallIcon {
  margin-bottom: 40px;
}
#tooSmallIcon img {
  max-height: 100px;
}
</style>
